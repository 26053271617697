import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Ship moves in a snake-like way. Bullets move in a snake-like way.
*/
export const shipDescrip8 = `
movement: ship moves in a snake-like way.
weapon: bullets move in a snake-like way.`;

export const ship8 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'vel', new Vector2(0, 0));
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
        HARNESS.setTrait(e.ship, 'moveTimer', HARNESS.createTimer(e.game));
        e.ship.rot = getRandomRot();
    });

    function getRandomRot() {
        var rot;
        var randDir = HARNESS.randInt(0, 4);
        if (randDir == 0) rot = 0
        else if (randDir == 1) rot = Math.PI / 2;
        else if (randDir == 3) rot = Math.PI;
        else rot = (3 * Math.PI) / 2;
        return rot;
    }

    eventHandler.addEventToSet('onShoot', (e) => {
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.05);
        bullet.pos = new Vector2(
            e.ship.pos.x,
            e.ship.pos.y,
        );
        var rot = getRandomRot();
        HARNESS.setTrait(bullet, 'vel', new Vector2(Math.cos(rot), Math.sin(rot)));
        HARNESS.setTrait(bullet, 'moveTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Move.
        HARNESS.doIfHasTrait(e.ship, 'moveTimer', (moveTimer) => {
            if (moveTimer.getElapsedTime() > 1) {
                e.ship.rot = getRandomRot();
                e.ship.traits['moveTimer']?.start();
            }
        });

        var vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(1.0);
        e.ship.pos.add(vel.multiplyScalar(e.dt));

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            if (shootTimer.getElapsedTime() > 0.2) {
                // fires every 0.2 seconds.
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        });
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        HARNESS.doIfHasTrait(e.bullet, 'moveTimer', (moveTimer) => {
            if (moveTimer.getElapsedTime() > 1) {
                var rot = getRandomRot();
                HARNESS.setTrait(e.bullet, 'vel', new Vector2(Math.cos(rot), Math.sin(rot)));
                moveTimer.start();
            }
        });

        HARNESS.doIfHasTrait(e.bullet, 'vel', (vel) => {
            var SPEED = 2.0;
            var dpos = new Vector2(vel.x, vel.y)
                .multiplyScalar(SPEED)
                .multiplyScalar(e.dt);
            e.bullet.pos.add(dpos);
        });
    });
};
