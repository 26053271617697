import { InitShipEvent } from "../events/initShipEvent.ts";
import { Game } from "../model/game.ts";
import { ShipCollection } from "../model/objects/shipCollection.ts";
import { ShipMetaDataCollection } from "../model/objects/shipMetaDataCollection.ts";
import { loadAndExecuteShipFunction } from "../utils/codeCompilation.ts";

class InitSystem {

    private game: Game;

    constructor(game: Game) {
        this.game = game;
    }

    attach() {
        this.init();
    }

    detach() {
    }

    init() {
        var shipCollection = this.game.dataManager.read(ShipCollection);
        var metaDataCollection = this.game.dataManager.read(ShipMetaDataCollection);
        for (var key in shipCollection.ships) {
            var ship = shipCollection.ships[key];
            loadAndExecuteShipFunction(ship, metaDataCollection.metaData[ship.metaDataId]);
        }

        for (var key in shipCollection.ships) {
            var ship = shipCollection.ships[key];
            ship.eventHandler.callAllEventsInSet(
                'onInitShip',
                new InitShipEvent(this.game, ship)
            );
        }
    }
}

export { InitSystem }