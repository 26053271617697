import React, { useCallback, useState } from 'react'

import '../styles/Navigation.css';
import { useApi } from '../hooks/useApi.tsx';
import { PlayerInfo } from './PlayerInfo.tsx';

export function Navigation() {

    const { uploadAllExamples, uploadHarness } = useApi();

    const [showMenuOptions, setShowMenuOptions] = useState<string>("");


    const internalOnUploadExampleShips = useCallback(async () => {
        uploadAllExamples();
    }, [uploadAllExamples]);

    const internalOnUploadHarness = useCallback(async () => {
        uploadHarness();
    }, [uploadHarness]);

    return (
        <div className="Navigation crt">
            {/* <div className="NavOption">
                <a>register.</a>
            </div> */}
            <div className="NavTitle">
                <div className="NavTitleText">
                    <a href="./">STARSHIPS TV</a>&nbsp;
                </div>
                <div className="NavTitleAlpha">ALPHA!</div>
            </div>
            <div className="NavOption Login"
                onMouseEnter={() => setShowMenuOptions("login")}
                onMouseLeave={() => setShowMenuOptions("")}>
                player.
                <div className={`MenuOptions ${showMenuOptions === "login" ? 'visible' : ''}`}>
                    {/* <div
                    onMouseDown={internalOnUploadExampleShips}
                    >Upload Examples</div>
                    <div
                    onMouseDown={internalOnUploadHarness}
                    >Upload Harness</div> */}
                    <PlayerInfo interactable={true}/>
                </div>
            </div>
            <div className="NavOption">
                <a
                href="/about.html"
                target="_blank"
                rel="noopener noreferrer"
                >info.</a>
            </div>
            <div className="NavOption">
                <a 
                href="https://docs.google.com/forms/d/e/1FAIpQLSe3gt80CfaaDLD0UPmRDSoY26TrfTJgYyLo2d-0yaKQwRYEHA/viewform"
                target="_blank"
                rel="noopener noreferrer"
                >feedback.</a>
            </div>
            {process.env.REACT_APP_IS_DEBUG == '1' ? (
                <div className="NavOption Debug"
                    onMouseEnter={() => setShowMenuOptions("debug")}
                    onMouseLeave={() => setShowMenuOptions("")}>
                    debug.
                    <div className={`MenuOptions ${showMenuOptions == "debug" ? 'visible' : ''}`}>
                        <div
                        className="Option"
                        onMouseDown={internalOnUploadExampleShips}
                        >Upload Examples</div>
                        <div
                        className="Option"
                        onMouseDown={internalOnUploadHarness}
                        >Upload Harness</div>
                    </div>
                </div>
            ) : (null)}
        </div>
    );
}