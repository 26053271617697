import { Game } from "../model/game.ts";
import { Timer } from "../model/objects/timer.ts";
import { TimersCollection } from "../model/objects/timersCollection.ts";

class TimersSystem {

    private game: Game;

    constructor(game: Game) {
        this.game = game;

        this.update = this.update.bind(this);
        this.createNewTimer = this.createNewTimer.bind(this);
        this.cleanup = this.cleanup.bind(this);
    }

    attach() {
        this.game.gameEventHandlers.addEventToSet('update', this.update);
        this.game.gameEventHandlers.addEventToSet('createNewTimer', this.createNewTimer);
        this.game.gameEventHandlers.addEventToSet('cleanup', this.cleanup);
    }

    detach() {
        this.game.gameEventHandlers.removeEventFromSet('update', this.update);
        this.game.gameEventHandlers.removeEventFromSet('createNewTimer', this.createNewTimer);
        this.game.gameEventHandlers.removeEventFromSet('cleanup', this.cleanup);
    }

    cleanup() {
        var timerCollection: TimersCollection = this.game.dataManager.read(TimersCollection);
        delete timerCollection.timers;
        this.game.dataManager.write(timerCollection);
    }

    createNewTimer() {
        var timerCollection: TimersCollection = this.game.dataManager.read(TimersCollection);
        var timer = new Timer(true);
        timerCollection.timers[timerCollection.nextId++] = timer;
        this.game.dataManager.write(timerCollection);
    }

    update(dt: number) {
        var timerCollection: TimersCollection = this.game.dataManager.read(TimersCollection);

        for (var key in timerCollection.timers) {
            var timer = timerCollection.timers[key];
            timer.update(dt);
        }

        this.game.dataManager.write(timerCollection);
    }
}

export { TimersSystem }