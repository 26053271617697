import { AnimateEnterSystem } from "../physics/animateEnterSystem.ts";
import { State } from "../utils/stateHandler.ts";
import { Game } from "./game.ts";

class StartState extends State {

    constructor(game: Game) {
        super();

        this.attach(new AnimateEnterSystem(game));
    }
}

export { StartState }