import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useSelectedShips } from './useSelectedShips.tsx';
import { useBattleSequence } from './useBattleSequence.tsx';
import { useApi } from './useApi.tsx';
import { usePlayerInfo } from './usePlayerInfo.tsx';
import { useGameState } from './useGameState.tsx';

const CurrentBattleStateContext = React.createContext({
    isBattleStarted: false,
    onStartBattle: () => {},
    onEndBattle: () => {},
    winner: null,
    setWinner: () => {},
    speed: 1,
    onChangeSpeed: (isIncreased: boolean) => () => {},
});

export const CurrentBattleStateProvider = ({ children }) => {
    const { ship1, ship2, setShip1, setShip2 } = useSelectedShips();
    const {
        isSequenceStarted,
        setIsSequenceStarted,
        availableShips,
        setAvailableShip,
        resetAvailableShips,
    } = useBattleSequence();

    const { postBattleResult, getBattleSequenceShips, battleSequenceShips } = useApi();
    const { playerId } = usePlayerInfo();
    const { isPlaying } = useGameState();

    const [isBattleStarted, setIsBattleStarted] = useState<boolean>(false);
    const [winner, setWinner] = useState<number | null>(null);
    const [speed, setSpeed] = useState<number>(1);

    const onStartBattle = () => {
        setIsBattleStarted(true);
    };

    const onEndBattle = useCallback(() => {
        setIsBattleStarted(false);
        var isFinalBattle =
            Object.values(availableShips).filter((v) => v !== 'defeated')
                .length === 1;
        var playerShipId = ship1;
        var winnerShipId = '';
        var loserShipId = '';
        if (isSequenceStarted) {
            if (winner == null) {
                setAvailableShip(ship2, 'skipped');
            } else if (winner === 0) {
                winnerShipId = ship1;
                loserShipId = ship2;
                setAvailableShip(ship2, 'defeated');
                if (isFinalBattle) {
                    getBattleSequenceShips();
                }
            } else {
                winnerShipId = ship2;
                loserShipId = ship1;
                setShip1('');
                setIsSequenceStarted(false);
                resetAvailableShips();
            }
        } else {
            setShip1('');
        }
        setShip2('');
        setWinner(null);
        postBattleResult(
            {
                is_final: isFinalBattle,
                player_id: playerShipId,
                winner_id: winnerShipId,
                loser_id: loserShipId,
            },
            playerId
        );
    }, [
        availableShips,
        isSequenceStarted,
        playerId,
        postBattleResult,
        resetAvailableShips,
        setAvailableShip,
        setIsSequenceStarted,
        setShip1,
        setShip2,
        ship1,
        ship2,
        winner,
    ]);

    const onChangeSpeed = useCallback(
        (isIncreased: boolean) => () => {
            if (speed > 1) {
                setSpeed(speed + (isIncreased ? 1 : -1));
                return;
            }

            if (isIncreased) {
                if (speed === 1) {
                    setSpeed(speed + 1);
                } else {
                    setSpeed(Math.round(10 * (speed + 0.1)) * 0.1);
                }
            } else {
                if (speed === 0) {
                    return;
                } else {
                    setSpeed(Math.round(10 * (speed - 0.1)) * 0.1);
                }
            }
        },
        [speed]
    );

    useEffect(() => {
        if (ship2 !== '' && isSequenceStarted) {
            onStartBattle();
        }
    }, [ship2, isSequenceStarted]);

    useEffect(() => {
        if (isPlaying && (battleSequenceShips == null || battleSequenceShips.length == 0)) {
            getBattleSequenceShips();
        }
    }, [isPlaying])

    return (
        <CurrentBattleStateContext.Provider
            value={{
                isBattleStarted,
                onStartBattle,
                onEndBattle,
                winner,
                setWinner,
                speed,
                onChangeSpeed,
            }}
        >
            {children}
        </CurrentBattleStateContext.Provider>
    );
};

export const useCurrentBattleState = () =>
    useContext(CurrentBattleStateContext);
