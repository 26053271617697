import { Scene, Vector2, Vector3, Quaternion } from "three";
import { ShipMetaDataCollection } from "../model/objects/shipMetaDataCollection.ts";
import { createExplodedOutlineMesh } from "./utils.ts";
import { Game } from "../model/game.ts";
import * as TWEEN from "@tweenjs/tween.js";
import { randFloat } from "../harness.ts";
import { ShipCollection } from "../model/objects/shipCollection.ts";
import { GameView } from "./gameView.ts";

class DeathView {

    private game: Game;
    private gameView: GameView;
    private scene: Scene;

    constructor(game: Game, gameView: GameView, scene: Scene) {
        this.game = game;
        this.gameView = gameView;
        this.scene = scene;

        this.collision = this.collision.bind(this);
        this.game.gameEventHandlers.addEventToSet("collision", this.collision);
    }

    collision(e) {
        var ship = e.ship;
        var shipMetaData = this.game.dataManager.read(ShipMetaDataCollection).metaData[ship.metaDataId];

        var explodedMeshes = createExplodedOutlineMesh(
            shipMetaData.vertices,
            10,
            `#${shipMetaData.color}`
        );

        if (e.bullet) {
            this.gameView.bulletViews.forEach((bulletView) => {
                if (bulletView.bulletId == e.bullet.id) {
                    new TWEEN.Tween(bulletView)
                        .delay(1000)
                        .start()
                        .onComplete(() => {
                            new TWEEN.Tween(bulletView.meshView.material.color)
                                .to({
                                    r: 1,
                                    g: 1,
                                    b: 1,
                                }, 10)
                                .delay(50)
                                .repeat(15)
                                .yoyo(true)
                                .start()
                        });
                }
            });
        }

        explodedMeshes.forEach(x => this.scene.add(x));
        explodedMeshes.forEach(x => {
            x.position.x = ship.pos.x;
            x.position.y = ship.pos.y;

            var rot = new Quaternion();
            rot.setFromAxisAngle(new Vector3(0, 0, 1), ship.rot);
            x.rotation.setFromQuaternion(rot);

            var normalRot = x.normal.clone();
            normalRot.applyQuaternion(rot);

            var offset = randFloat(0.1, 5.0);
            new TWEEN.Tween(x)
                .delay(100)
                .start()
                .onComplete(() => {
                    this.gameView.glitch();
                    new TWEEN.Tween(x.material.color)
                        .to({
                            r: 1,
                            g: 1,
                            b: 1,
                        }, 10)
                        .delay(50)
                        .repeat(15)
                        .yoyo(true)
                        .start()
                        .onComplete(() => {
                            new TWEEN.Tween(x.position)
                                .to({
                                    x: x.position.x + offset * normalRot.x,
                                    y: x.position.y + offset * normalRot.y,
                                    z: 0,
                                }, 1000)
                                .delay(200)
                                .easing(TWEEN.Easing.Exponential.Out)
                                .start()
                                .onComplete(() => {
                                    this.game.stateHandler.transition('end');
                                });
                        });
                })
        });
    }
}

export { DeathView }