import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Shoots forward fast. Faces the nearest enemy and moves towards them.
*/
export const shipDescrip3 = `
movement: faces the nearest enemy and moves towards them.
weapon: shoots forward fast.`;

export const ship3 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.05);
        bullet.pos = new Vector2(
            e.ship.pos.x,
            e.ship.pos.y,
        );
        const speed = 6.0;
        const vel = HARNESS.randomDirectionVector2();
        vel.multiplyScalar(speed);
        HARNESS.setTrait(bullet, 'vel', vel);
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Find nearest enemy.
        var closest = HARNESS.getClosestShipsTo(e.game, e.ship)[0];
        if (closest != null) {
            var dist = new Vector2();
            dist.subVectors(closest.pos, e.ship.pos);
            dist.normalize();
            e.ship.rot = HARNESS.getRotFromPlaneVector2(dist);
        }

        // Move.
        var speed = 1.0;
        var vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(speed);
        e.ship.pos.add(vel.multiplyScalar(e.dt));

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            var tElapsed = shootTimer.getElapsedTime();
            if (tElapsed > 0.2) {
                // fires every 0.2 seconds.
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        });
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        HARNESS.doIfHasTrait(e.bullet, 'vel', (vel) => {
            var dpos = new Vector2(vel.x, vel.y).multiplyScalar(
                e.dt
            );
            e.bullet.pos.add(dpos);
        });
    });
};
