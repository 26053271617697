import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Shoots a bullet in a random direction every 0.2 seconds. Moves to the right slowly.
*/
export const shipDescrip0 = `
movement: moves to the right slowly.
weapon: shoots a bullet in a random direction every 0.2 seconds.`;

export const ship0 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.1);
        bullet.pos = new Vector2(e.ship.pos.x, e.ship.pos.y);
        const speed = 3.0;
        const vel = HARNESS.randomDirectionVector2();
        vel.multiplyScalar(speed);
        HARNESS.setTrait(bullet, 'vel', vel);
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Move.
        var speed = 1.0;
        var vel = new Vector2(speed, 0);
        e.ship.pos.add(vel.multiplyScalar(e.dt));

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            var tElapsed = shootTimer.getElapsedTime();
            if (tElapsed > 0.2) {
                // fires every 0.2 seconds.
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        });
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        HARNESS.doIfHasTrait(e.bullet, 'vel', (vel) => {
            var dpos = new Vector2(vel.x, vel.y).multiplyScalar(
                e.dt
            );
            e.bullet.pos.add(dpos);
        });
    });
};
