import { BoundarySystem } from "../physics/boundarySystem.ts";
import { CollisionSystem } from "../physics/collisionSystem.ts";
import { InitSystem } from "../physics/initSystem.ts";
import { LoggingSystem } from "../physics/loggingSystem.ts";
import { TimersSystem } from "../physics/timersSystem.ts";
import { UpdateSystem } from "../physics/updateSystem.ts";
import { State } from "../utils/stateHandler.ts";
import { Game } from "./game.ts";

class PlayState extends State {

    constructor(game: Game) {
        super();
        this.attach(new CollisionSystem(game));
        this.attach(new BoundarySystem(game));
        this.attach(new TimersSystem(game));
        this.attach(new InitSystem(game));
        this.attach(new UpdateSystem(game));
        // this.attach(new LoggingSystem(game));
    }
}

export { PlayState }