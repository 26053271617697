import { Game } from "../model/game.ts";
import { BattleData } from "../model/objects/battleData.ts";
import { Environment } from "../model/objects/environment.ts";
import { ShipCollection } from "../model/objects/shipCollection.ts";

class BoundarySystem {

    private game: Game;

    constructor(game: Game) {

        this.game = game;

        this.update = this.update.bind(this);
    }

    attach() {
        this.game.gameEventHandlers.addEventToSet("update", this.update);
    }

    detach() {
        this.game.gameEventHandlers.removeEventFromSet("update", this.update);
    }

    update(dt) {
        var environment: Environment = this.game.dataManager.read(Environment);
        var shipCollection = this.game.dataManager.read(ShipCollection);
        for (var key in shipCollection.ships) {
            var ship = shipCollection.ships[key];
            if (ship.pos.x < environment.boundsLeft
                || ship.pos.x > environment.boundsRight
                || ship.pos.y < environment.boundsBottom
                || ship.pos.y > environment.boundsTop) {
                    this.game.collision(ship, null);
                }
        }

        var battleData: BattleData = this.game.dataManager.read(BattleData);
        battleData.timeLeftSeconds = Math.max(0, battleData.timeLeftSeconds - dt);
        if (battleData.timeLeftSeconds <= 0) {
            environment.boundsLeft = Math.min(0, environment.boundsLeft + environment.shrinkSpeed * dt / 2.0);
            environment.boundsRight = Math.max(0, environment.boundsRight - environment.shrinkSpeed * dt / 2.0);
            environment.boundsTop = Math.max(0, environment.boundsTop - environment.shrinkSpeed * dt / 2.0);
            environment.boundsBottom = Math.min(0, environment.boundsBottom + environment.shrinkSpeed * dt / 2.0);
            this.game.dataManager.write(environment);
        }
    }
}

export { BoundarySystem }