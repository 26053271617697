import { Game } from "../model/game.ts";

class TVViewSystem {

    private game: Game;

    constructor(game: Game) {
        this.game = game;

        this.update = this.update.bind(this);
    }

    attach() {
        this.game.gameEventHandlers.addEventToSet("update", this.update);
    }

    detach() {
        this.game.gameEventHandlers.removeEventFromSet("update", this.update);
    }

    update(dt) {

    }
}

export { TVViewSystem }