import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Moves up/down & back and forth in a sin wave. Shoots bullets that bounce off the walls and die after 2 bounces.
*/
export const shipDescrip6 = `
movement: moves up/down & back and forth in a sin wave.
weapon: shoots bullets that bounce off the walls and die after 2 bounces.`;

export const ship6 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'originPos', new Vector2(
            e.ship.pos.x,
            e.ship.pos.y,
        ));
        HARNESS.setTrait(e.ship, 'angle', 0.0);
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.1);
        bullet.pos = new Vector2(
            e.ship.pos.x,
            e.ship.pos.y,
        );
        const speed = 3.0;
        const vel = HARNESS.randomDirectionVector2();
        vel.multiplyScalar(speed);
        HARNESS.setTrait(bullet, 'vel', vel);
        HARNESS.setTrait(bullet, 'bounceCount', 0);
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Move.
        HARNESS.doIfHasTrait(e.ship, 'angle', (angle) => {
            var speed = 1.0;
            var freqX = 1.0;
            var freqY = 10.0;
            HARNESS.setTrait(e.ship, 'angle', angle + speed * e.dt);
            e.ship.pos = new Vector2(
                3.0 * Math.sin(freqX * angle),
                1.0 * Math.cos(freqY * angle),
            );
        })

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            if (shootTimer.getElapsedTime() > 0.2) {
                // fires every 0.2 seconds.
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        })
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        HARNESS.doIfHasTraits(e.bullet, ['vel', 'bounceCount'], (vel, bounceCount) => {
            var dpos = new Vector2(vel.x, vel.y).multiplyScalar(
                e.dt
            );
            e.bullet.pos.add(dpos);

            var boundsMin = HARNESS.getWorldBoundsMinVec2(e.game);
            var boundsMax = HARNESS.getWorldBoundsMaxVec2(e.game);
            var bounce = false;
            if (e.bullet.pos.x < boundsMin.x) {
                vel.multiply(new Vector2(-1, 1));
                e.bullet.pos.x = boundsMin.x;
                bounce = true;
            } else if (e.bullet.pos.x > boundsMax.x) {
                vel.multiply(new Vector2(-1, 1));
                e.bullet.pos.x = boundsMax.x;
                bounce = true;
            } else if (e.bullet.pos.y < boundsMin.y) {
                vel.multiply(new Vector2(1, -1));
                e.bullet.pos.y = boundsMin.y;
                bounce = true;
            } else if (e.bullet.pos.y > boundsMax.y) {
                vel.multiply(new Vector2(1, -1));
                e.bullet.pos.y = boundsMax.y;
                bounce = true;
            }

            if (bounce) {
                HARNESS.setTrait(e.bullet, 'bounceCount', bounceCount + 1);
            }

            HARNESS.setTrait(e.bullet, 'vel', vel);

            if (bounceCount >= 2) {
                HARNESS.deleteBullet(e.game, e.bullet);
            }
        })
    });
};
