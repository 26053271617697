import * as THREE from 'three';
import { BulletCollection } from '../model/objects/bulletCollection.ts';
import shapes from '../../../lib/shapes.ts';
import { createOutlineMesh } from './utils.ts';
import { ShipCollection } from '../model/objects/shipCollection.ts';
import { ShipMetaDataCollection } from '../model/objects/shipMetaDataCollection.ts';

class BulletView {
    constructor(dataManager, bulletId) {
        this.dataManager = dataManager;
        this.bulletId = bulletId;

        var bullet = dataManager.read(BulletCollection).bullets[bulletId];
        var ship = dataManager.read(ShipCollection).ships[bullet.shipId];
        var shipMetaData = dataManager.read(ShipMetaDataCollection).metaData[ship.metaDataId];

        this.meshView = createOutlineMesh(
            shapes.oblong,
            10,
            `#${shipMetaData.color}`
        );
        this.meshView.scale.set(bullet.scale, bullet.scale, bullet.scale);
    }

    addToScene(scene) {
        scene.add(this.meshView);
    }

    removeFromScene(scene) {
        scene.remove(this.meshView);
    }

    draw() {
        var bullet = this.dataManager.read(BulletCollection).bullets[this.bulletId]
        this.meshView.position.x = bullet.pos.x;
        this.meshView.position.y = bullet.pos.y;
        this.meshView.position.z = 0.0;
    }
}

export { BulletView };
