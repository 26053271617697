import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useSelectedShips } from '../hooks/useSelectedShips.tsx';
import { useApi } from '../hooks/useApi.tsx';
import { useCurrentBattleState } from './useCurrentBattleState.tsx';

type AvailableShip = 'player' | 'undefeated' | 'defeated' | 'skipped';

type AvailableShips = { [key: string]: AvailableShip };

const BattleSequenceContext = React.createContext({
    availableShips: {} as AvailableShips,
    setAvailableShip: (shipIndex: string, shipState: AvailableShip) => {},
    resetAvailableShips: () => {},
    isSequenceStarted: false,
    setIsSequenceStarted: (isSequenceStarted: boolean) => {},
});

export const BattleSequenceProvider = ({ children }) => {
    const { battleSequenceShips } = useApi();
    const { ship1, ship2, setShip2 } = useSelectedShips();

    const [availableShips, setAvailableShips] = useState<AvailableShips>(
        battleSequenceShips.reduce((acc, ship) => {
            acc[ship.id] = 'undefeated';
            return acc;
        }, {} as AvailableShips)
    );
    const [isSequenceStarted, setIsSequenceStarted] = useState<boolean>(false);

    const setAvailableShip = useCallback(
        (shipId: string, shipState: AvailableShip) => {
            const newAvailableShips = { ...availableShips };
            newAvailableShips[shipId] = shipState;
            setAvailableShips(newAvailableShips);
        },
        [availableShips]
    );

    const resetAvailableShips = useCallback(() => {
        setAvailableShips(
            battleSequenceShips.reduce((acc, ship) => {
                acc[ship.id] = 'undefeated';
                return acc;
            }, {} as AvailableShips)
        );
    }, [battleSequenceShips]);

    useEffect(() => {
        if (isSequenceStarted && ship2 === '') {
            for (let i = 0; i < battleSequenceShips.length; i++) {
                if (
                    availableShips[battleSequenceShips[i].id] ===
                    'undefeated'
                ) {
                    setShip2(battleSequenceShips[i].id);
                    return;
                }
            }
        }
    }, [
        availableShips,
        battleSequenceShips,
        isSequenceStarted,
        setShip2,
        ship1,
        ship2,
    ]);

    useEffect(() => {
        if (ship1 !== '')
        {
            resetAvailableShips();
            setIsSequenceStarted(true);
        }
    }, [ship1]);

    return (
        <BattleSequenceContext.Provider
            value={{
                availableShips,
                setAvailableShip,
                resetAvailableShips,
                isSequenceStarted,
                setIsSequenceStarted,
            }}
        >
            {children}
        </BattleSequenceContext.Provider>
    );
};

export const useBattleSequence = () => useContext(BattleSequenceContext);
