import { Game } from "../model/game";
import { Ship } from "../model/objects/ship";

class InitShipEvent {

    public game: Game;
    public ship: Ship;

    constructor(game: Game, ship: Ship) {
        this.game = game;
        this.ship = ship;
    }
}

export { InitShipEvent };
