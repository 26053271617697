type EventCallback = (...args: any[]) => void;

class EventHandler {
    private events: Record<string, EventCallback[]>;
    constructor() {
        this.events = {};
    }

    addEventSet(name: string) {
        this.events[name] = [];
    }

    addEventToSet(name: string, callback: EventCallback) {
        if (name in this.events) {
            this.events[name].push(callback);
        } else {
            console.error("Attempted to add " + callback + " to event: " + name);
        }
    }

    removeEventFromSet(name: string, callback: EventCallback) {
        if (name in this.events) {
            this.events[name].splice(this.events[name].indexOf(callback), 1);
        } else {
            console.error("Attempted to remove " + callback + " from event: " + name);
        }

    }

    callAllEventsInSet(name: string, ...args: any[]) {
        this.events[name].forEach((callback: EventCallback) => {
            callback(...args);
        });
    }

    callAllEventsInSetWithFallback(name: string, ...args: any[]) {
        this.events[name].forEach((callback: EventCallback) => {
            callback(...args);
        });
    }
}

export { EventHandler };

