import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Shoots 1 big bullet forward every 5 seconds. Moves forward, and faces a new random direction every 2 seconds.
*/
export const shipDescrip1 = `
movement: moves forward, and faces a new random direction every 2 seconds.
weapon: shoots 1 big bullet forward every 5 seconds.`;

export const ship1 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'vel', new Vector2(0, 0));
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
        HARNESS.setTrait(e.ship, 'moveTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.8);
        bullet.pos = new Vector2(e.ship.pos.x, e.ship.pos.y);
        const vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(0.8);
        HARNESS.setTrait(bullet, 'vel', vel);
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Move.
        HARNESS.doIfHasTrait(e.ship, 'moveTimer', (moveTimer) => {
            var tMoveElapsed = moveTimer.getElapsedTime();
            if (tMoveElapsed > 2) {
                e.ship.rot = HARNESS.randomPlaneRotation();
                moveTimer.start();
            }
        })

        var vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(1.2);
        e.ship.pos.add(vel.multiplyScalar(e.dt));

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            var tShootElapsed = shootTimer.getElapsedTime();
            if (tShootElapsed > 5) {
                // fires every 0.2 seconds.
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        });
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        HARNESS.doIfHasTrait(e.bullet, 'vel', (vel) => {
            var dpos = new Vector2(vel.x, vel.y).multiplyScalar(
                e.dt
            );
            e.bullet.pos.add(dpos);
        })
    });
};
