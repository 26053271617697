import { RefObject } from 'react';

import * as THREE from 'three';

import { Game } from './model/game.ts';
import { ShipMetaData } from './model/objects/shipMetaData.ts';

import { GameView } from './views/gameView.ts';
import { ShipData } from '../../ui/hooks/useApi.js';

export default function main(
    ref: RefObject<HTMLDivElement>,
    refLabel: RefObject<HTMLDivElement>,
    ships: Array<ShipData>,
    setWinner: (shipNumber: number) => void,
    speed: number
) {
    //-- GAME SETUP --
    var game = new Game(setWinner);

    ships.forEach((ship) => {
        game.addNewShipMetaData(
            new ShipMetaData(
                ship.name,
                ship.aesthetics.color,
                ship.vertices,
                ship.eventHandlers,
            )
        );
    });

    // View Setup.
    var gameView = new GameView(ref, refLabel, game);

    // Spawn ships.
    ships.forEach((ship, index) => {
        game.addNewShip(
            // TODO: temp positioning assuming 2-player.
            new THREE.Vector2(-4 + 8 * index, 0),
            0.0,
            1.0,
            index,
        );
    });

    var clock = new THREE.Clock();
    clock.start();

    // Start.
    game.stateHandler.transition("start");

    var culmPhyDt = 0;
    function animate() {
        var dtView = clock.getDelta();
        var dtPhys = 0.016;
        culmPhyDt += dtView;

        // UPDATE MODEL
        if (culmPhyDt >= dtPhys) {
            var phySteps = Math.floor(culmPhyDt / dtPhys);
            for (var i = 0; i < phySteps; ++i) {
                game.update(dtPhys * speed);
            }
            var remainderDt = culmPhyDt % dtPhys;
            if (remainderDt > 0) {
                game.update(remainderDt * speed);
            }
            culmPhyDt = 0;
        }
        gameView.update(dtView * speed);
        // -----------

        // UPDATE VIEW
        requestAnimationFrame(animate);
        gameView.draw();
        // -----------
    }

    animate();
}
