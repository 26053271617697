import { Camera, MathUtils, Vector2, Vector3, Quaternion } from "three";
import { ShipCollection } from "../model/objects/shipCollection.ts";
import { GameView } from "./gameView";
import { Game } from "../model/game";
import { Bullet } from "../model/objects/bullet.ts";
import { Ship } from "../model/objects/ship.ts";

class CameraController {

    public targets: Array<Ship | Bullet>;

    private gameView: GameView;
    private game: Game;
    private camera: Camera;
    private speed: number;
    private speedRot: number;
    private battleZoomOut: number = 0.3;

    constructor(gameView: GameView, game: Game, camera: Camera) {
        this.targets = [];
        this.gameView = gameView;
        this.game = game;
        this.camera = camera;
        this.speed = 5.0;
        this.speedRot = 0.1;

        this.update = this.update.bind(this);
        this.collision = this.collision.bind(this);
        this.onChangeState = this.onChangeState.bind(this);

        gameView.gameViewEventHandlers.addEventToSet('update', this.update);
        game.gameEventHandlers.addEventToSet('collision', this.collision);
        game.stateHandler.eventHandler.addEventToSet('transition', this.onChangeState);
    }

    onChangeState(state: string) {
        if (state == "start") {
            this.initPlayState();
        }
    }

    initPlayState() {
        var ships: Record<string, Ship> = this.game.dataManager.read(ShipCollection).ships;
        this.targets = [];
        for (const [id, ship] of Object.entries(ships)) {
            this.targets.push(ship);
        };

        this.camera.scale.x = 10.0;
        this.camera.scale.y = 10.0;
    }
    
    trackTargets(dt: number) {
        var numTargets = Object.entries(this.targets).length;
        if (numTargets == 0) {
            return;
        }

        var com = new Vector2(0, 0);
        for (let i = 0; i < this.targets.length; ++i) {
            com.add(this.targets[i].pos);
        };
        com.divideScalar(numTargets);
        var dToPos = new Vector3(com.x, com.y, this.camera.position.z).sub(this.camera.position);
        var dSpeed = dt * this.speed * dToPos.lengthSq();
        var vel = dToPos.normalize().multiplyScalar(dSpeed);
        if (dSpeed > dToPos.length()) {
            vel = dToPos;
        }

        var zoomTarget = 0.5 + (numTargets - 1) * this.battleZoomOut;
        if (this.game.stateHandler.getCurrentState() == 'death') {
            zoomTarget = 0.2;
        }
        var dZoom = zoomTarget - this.camera.scale.x;
        var zSpeed = this.speed * Math.pow(dZoom, 2) * dt;
        var zoomVel = Math.sign(dZoom) * zSpeed;
        if (zSpeed > Math.abs(dZoom)) {
            zoomVel = dZoom;
        }

        if (numTargets > 1) {
            var dShips = new Vector2(this.targets[0].pos.x - this.targets[1].pos.x, this.targets[0].pos.y - this.targets[1].pos.y);
            var rotZTarget = dShips.x < 0.1 ? 0.0 : Math.atan(dShips.y / dShips.x);
            var dRot = rotZTarget - this.camera.rotation.z;
            var rSpeed = this.speedRot * Math.pow(dRot, 2) * dt;
            var rotVel = Math.sign(dRot) * rSpeed;
            if (rSpeed > Math.abs(dRot)) {
                rotVel = dRot;
            }

            var rot = new Quaternion();
            rot.setFromAxisAngle(new Vector3(0, 0, 1), this.camera.rotation.z + rotVel);
            this.camera.rotation.setFromQuaternion(rot);
        }

        this.camera.position.x += vel.x;
        this.camera.position.y += vel.y;
        this.camera.position.z += vel.z;
        this.camera.scale.x += zoomVel;
        this.camera.scale.y += zoomVel;
    }

    update(dt) {
        this.trackTargets(dt);
    }

    collision(e) {
        this.targets = [];
        if (e.bullet) {
            this.targets.push(e.bullet);
        }
        if (e.ship) {
            this.targets.push(e.ship);
        }
    }
}

export { CameraController }