import { Vector2 } from 'three';
import { EventHandler } from '../../eventHandler.ts';
import { Bullet } from './bullet.ts';

class Ship {
    static TYPE = "Ship";

    public pos: Vector2;
    public rot: number;
    public scale: number;
    public traits: Record<string, any>;
    public id: number;
    public metaDataId: number;
    public eventHandler: EventHandler;

    constructor(pos: Vector2, rot: number, scale: number, id: number, metaDataId: number) {
        this.pos = pos;
        this.rot = rot;
        this.scale = scale;
        this.traits = {};
        this.id = id;
        this.metaDataId = metaDataId;

        this.eventHandler = new EventHandler();
        this.eventHandler.addEventSet('onInitShip');
        this.eventHandler.addEventSet('onShoot');
        this.eventHandler.addEventSet('onUpdateBullets');
        this.eventHandler.addEventSet('onUpdateShip');

        // Safety system for field-based trait access.
        return new Proxy(this, {
            get: (target, prop: any, receiver) => {
              if (prop in target) {
                return Reflect.get(target, prop, receiver);
              }
              return target.traits[prop];
            },
            set: (target, prop: any, value, receiver) => {
              if (prop in target) {
                Reflect.set(target, prop, value, receiver);
              } else {
                target.traits[prop] = value;
              }
              return true;
            }
        });
    }

    getType() { return Ship.TYPE; }
    static getType() { return Ship.TYPE; }
}

export { Ship };
