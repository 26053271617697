import { TVViewSystem } from "../physics/tvViewSystem.ts";
import { State } from "../utils/stateHandler.ts";
import { Game } from "./game.ts";

class TVState extends State {

    constructor(game: Game) {
        super();

        this.attach(new TVViewSystem(game));
    }
}

export { TVState }