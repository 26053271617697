import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Shoots one boomerang bullet. Moves forward, and faces a new random direction every 1 seconds.
*/
export const shipDescrip5 = `
movement: moves forward, and faces a new random direction every 1 second.
weapon: shoots one boomerang bullet.`;

export const ship5 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'vel', new Vector2(0, 0));
        HARNESS.setTrait(e.ship, 'moveTimer', HARNESS.createTimer(e.game));
        HARNESS.setTrait(e.ship, 'bulletActive', false);
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.2);
        bullet.pos = new Vector2(
            e.ship.pos.x,
            e.ship.pos.y,
        );
        HARNESS.setTrait(bullet, 'speed', 3.0); // speed is in rad/sec.
        HARNESS.setTrait(bullet, 'angle', 0.0);
        HARNESS.setTrait(bullet, 'angleOffset', HARNESS.randFloat(0.0, Math.PI));
        HARNESS.setTrait(bullet, 'axisScaleX', HARNESS.randFloat(0.7, 3.3));
        HARNESS.setTrait(bullet, 'axisScaleY', HARNESS.randFloat(0.7, 3.3));
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Move.
        HARNESS.doIfHasTrait(e.ship, 'moveTimer', (moveTimer) => {
            if (moveTimer.getElapsedTime() > 1) {
                e.ship.rot = HARNESS.randomPlaneRotation();
                moveTimer.start();
            }
        })

        var vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(2.0);
        e.ship.pos.add(vel.multiplyScalar(e.dt));

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'bulletActive', (bulletActive) => {
            if (!bulletActive) {
                HARNESS.shoot(e.game, e.ship);
                HARNESS.setTrait(e.ship, 'bulletActive', true);
            }
        })
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        HARNESS.doIfHasTraits(e.bullet, ['speed', 'axisScaleX', 'axisScaleY', 'angle', 'angleOffset'],
        (speed, axisScaleX, axisScaleY, angle, angleOffset) => {
            HARNESS.setTrait(e.bullet, 'angle', angle + speed * e.dt);
            var angleMod = Math.PI - Math.abs(angle - Math.PI);
            e.bullet.pos = new Vector2(
                angleMod * axisScaleX * Math.cos(angle + angleOffset),
                angleMod * axisScaleY * Math.sin(angle + angleOffset),
            );
            e.bullet.pos.add(e.ship.pos);

            if (angle >= Math.PI * 2.0) {
                e.game.removeBullet(e.bullet);
                HARNESS.setTrait(e.ship, 'bulletActive', false);
            }
        });
    });
};
