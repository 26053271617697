
class BattleData {
    static TYPE = "BattleData";

    public battleLengthSeconds: number;
    public timeLeftSeconds: number;

    constructor() {
        this.battleLengthSeconds = 30.0;
        this.timeLeftSeconds = this.battleLengthSeconds;
    }

    getType() { return BattleData.TYPE; }
    static getType() { return BattleData.TYPE; }
}

export { BattleData };