import React from 'react';

import '../styles/Splashscreen.css';
import '../styles/crt.css';
import useWindowDimensions from '../hooks/useWindowDimensions.tsx';
import { useGameState } from '../hooks/useGameState.tsx';
import { PlayerInfo } from './PlayerInfo.tsx';
import { usePlayerInfo } from '../hooks/usePlayerInfo.tsx';

export function Splashscreen() {
    const { isPlaying, setIsPlaying } = useGameState();
    const { height, width } = useWindowDimensions();
    const { inputPlayerId } = usePlayerInfo();

    function getGameCanPlay() {
        var ratio = width / height;
        if (height > 600 && width > 768 && ratio > 1.4) {
            return true;
        } else {
            return false;
        }
    }

    function onEnabled() {
        return inputPlayerId;
    }

    return (
        <div
            className={`Overlay crt ${
                !isPlaying || !getGameCanPlay() ? 'visible' : ''
            }`}
        >
            <div className="Items">
                <div className="TitleContainer">
                    <div className="Title">STARSHIPS TV</div>
                    <div className="NavTitleAlpha Splash">ALPHA!</div>
                </div>
                {getGameCanPlay() ? (
                    <>
                        <div className="PlayerIdContainer">
                            <PlayerInfo
                                interactable={true}
                                loginButton={false}
                            />
                        </div>
                        <div className="ButtonContainer">
                            <div
                                className={`StartButton ${
                                    onEnabled() ? '' : 'disabled'
                                }`}
                                onClick={() =>
                                    onEnabled() ? setIsPlaying(true) : null
                                }
                            >
                                ON
                            </div>
                            <div className="StartButton">
                                <a
                                    className="ButtonLink"
                                    href="/about.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    INFO
                                </a>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="StartInfo">
                            (Mobile & tablet support coming soon.)
                        </div>
                        <div
                            className={`StartButton About`}
                            onClick={() => window.open('/about.html')}
                        >
                            Info
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
