import * as THREE from 'three';
import { MathUtils } from 'three';

import { ShipCollection } from './model/objects/shipCollection.ts';
import { Environment } from './model/objects/environment.ts';
import { Game } from './model/game.ts';
import { Ship } from './model/objects/ship.ts';
import { Bullet } from './model/objects/bullet.ts';

export function spawnBullet(game: Game, ship: Ship, scale: number) {
    return game.addBullet(scale, ship.id);
}

export function deleteBullet(game: Game, bullet: Bullet) {
    game.removeBullet(bullet);
}

export function shoot(game: Game, ship: Ship) {
    game.shoot(ship);
}

export function createTimer(game: Game) {
    return game.createNewTimer();
}

export function planeVector2FromAngle(angle: number) {
    var vec = new THREE.Vector2(Math.cos(angle), Math.sin(angle));
    return vec;
}

export function randomDirectionVector2() {
    const randAngle = randFloat(0, Math.PI * 2.0);
    return planeVector2FromAngle(randAngle);
}

export function randomPlaneRotation() {
    const randAngle = randFloat(0, Math.PI * 2.0);
    return randAngle;
}

export function getClosestShipsTo(game: Game, ship: Ship) {
    var closestShips = [];
    var shipCollection = game.dataManager.read(ShipCollection);
    for (var key in shipCollection.ships) {
        if (ship.id.toString() != key) {
            var otherShip = shipCollection.ships[key];
            closestShips.push(otherShip);
        }
    }
    // TODO: sort by distance.
    return closestShips;
}

export function getRotFromPlaneVector2(vec2: THREE.Vector2) {
    var angle = Math.atan2(vec2.y, vec2.x);
    return angle;
}

export function getForward(rot: number) {
    var vec = new THREE.Vector2(Math.cos(rot), Math.sin(rot));
    return vec;
}

export function getBackward(rot: number) {
    var vec = getForward(rot).multiplyScalar(-1);
    return vec;
}

export function getRight(rot: number) {
    var vec = new THREE.Vector2(Math.cos(rot + Math.PI / 2), Math.sin(rot + Math.PI / 2));
    return vec;
}

export function getLeft(rot: number) {
    var vec = getRight(rot).multiplyScalar(-1);
    return vec;
}

export function getWorldBoundsMinVec2(game: Game) : THREE.Vector2 {
    var environment = game.dataManager.read(Environment);
    return new THREE.Vector2(environment.boundsLeft, environment.boundsBottom);
}

export function getWorldBoundsMaxVec2(game: Game) : THREE.Vector2 {
    var environment = game.dataManager.read(Environment);
    return new THREE.Vector2(environment.boundsRight, environment.boundsTop);
}

export function doIfHasTrait(obj: { traits: { [key: string]: any }}, key: string, method: Function) {
    if (key in obj.traits) {
        method(obj.traits[key]);
    }
}

export function doIfHasTraits(obj: { traits: { [key: string]: any }}, keys: Array<string>, method: Function) {
    // Failsafe.
    if (typeof keys === 'string') {
        doIfHasTrait(obj, keys, method);
    } else {
        var inputs: any[] = []
        keys.forEach(k => {
            if (k in obj.traits) {
                inputs.push(obj.traits[k])
            } else {
                return;
            }
        })
        method(...inputs);
    }
}

export function setTrait(obj: { traits: { [key: string]: any }}, key: string, value: any) {
    obj.traits[key] = value;
}

/* MATH */

export function randFloat(low: number, high: number): number {
    return MathUtils.randFloat(low, high);
}

export function randInt(low: number, high: number): number {
    return MathUtils.randInt(low, high);
}
