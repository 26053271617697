import * as THREE from 'three';
import { DataManager } from "../model/dataManager";

class TVView {

    private dataManager: DataManager;
    private viewPlane: THREE.Mesh;

    constructor(dataManager) {
        this.dataManager = dataManager;
    }

    addToScene(scene: THREE.Scene) {
        // const geometry = new THREE.PlaneGeometry(20, 20);
        // const material = new THREE.MeshBasicMaterial({
        //     color: 0xffffff,
        //     side: THREE.DoubleSide,
        // })
        // this.viewPlane = new THREE.Mesh(geometry, material);
        // scene.add(this.viewPlane);
    }

    draw() {

    }
}

export { TVView }