import { Bullet } from "./bullet";

class BulletCollection {
    static TYPE = "BulletCollection";

    public bullets: Record<string, Bullet>;
    public nextBulletId: number;

    constructor() {
        this.bullets = {};
        this.nextBulletId = 0;
    }

    getType() { return BulletCollection.TYPE; }
    static getType() { return BulletCollection.TYPE; }
}

export { BulletCollection };