import { ShipMetaDataCollection } from '../model/objects/shipMetaDataCollection.ts';
import { ShipCollection } from '../model/objects/shipCollection.ts';
import { createOutlineMesh, createFontMesh, createCssLabel } from './utils.ts';
import * as THREE from 'three';

class ShipView {
    constructor(dataManager, shipId) {
        this.dataManager = dataManager;
        this.shipId = shipId;
        var ship = dataManager.read(ShipCollection).ships[shipId];
        var shipMetaData = dataManager.read(ShipMetaDataCollection).metaData[
            shipId
        ];

        this.meshView = createOutlineMesh(
            shipMetaData.vertices,
            10,
            `#${shipMetaData.color}`
        );
        this.meshView.name = 'ship_' + shipMetaData.displayName;

        this.nameView = createCssLabel(shipMetaData.displayName + (this.shipId == 0 ? "\n(you)" : ""));
        this.nameView.element.style.color = `#${shipMetaData.color}`;
        this.nameView.element.style.fontSize = '12px';
    }

    name() {
        return this.meshView.name;
    }

    addToScene(scene) {
        scene.add(this.meshView);
        scene.add(this.nameView);
    }

    removeFromScene(scene) {
        scene.remove(this.meshView);
        scene.remove(this.nameView);
        this.nameView.remove();
    }

    draw() {
        var ship = this.dataManager.read(ShipCollection).ships[this.shipId];
        this.meshView.position.x = ship.pos.x;
        this.meshView.position.y = ship.pos.y;
        this.meshView.position.z = 0.0;
        this.meshView.scale.x = ship.scale;
        this.meshView.scale.y = ship.scale;
        this.meshView.scale.z = ship.scale;

        var rot = new THREE.Quaternion();
        rot.setFromAxisAngle(new THREE.Vector3(0, 0, 1), ship.rot);
        this.meshView.rotation.setFromQuaternion(rot);

        if (this.nameView) {
            this.nameView.position.x = this.meshView.position.x;
            this.nameView.position.y = this.meshView.position.y + 1.5;
            this.nameView.position.z = this.meshView.position.z;
        }
    }
}

export { ShipView };
