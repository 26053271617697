import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Shoots a bullet forward every 1.5 seconds that explodes into 8 mini bullets after 1 second. Does not move.
*/
export const shipDescrip2 = `
movement: does not move.
weapon: shoots a bullet forward every 1.5 seconds that explodes into 8 mini bullets after 1 second.`;

export const ship2 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        // spawn main bullet.
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.2);
        bullet.pos = new Vector2(
            e.ship.pos.x,
            e.ship.pos.y,
        );
        const speed = 1.0;
        const vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(speed);
        HARNESS.setTrait(bullet, 'vel', vel);
        HARNESS.setTrait(bullet, 'explodeTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            var tElapsed = shootTimer.getElapsedTime();
            if (tElapsed > 1.5) {
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        });
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        // move bullet.
        HARNESS.doIfHasTrait(e.bullet, 'vel', (vel) => {
            var dpos = new Vector2(vel.x, vel.y).multiplyScalar(
                e.dt
            );
            e.bullet.pos.add(dpos);
        })

        // check to explode bullet.
        HARNESS.doIfHasTrait(e.bullet, 'explodeTimer', (explodeTimer) => {
            if (explodeTimer.getElapsedTime() > 1) {
                var numBullets = 8;
                for (var i = 0; i < numBullets; ++i) {
                    var miniBullet = HARNESS.spawnBullet(e.game, e.ship, 0.1);
                    miniBullet.pos = new Vector2(
                        e.bullet.pos.x,
                        e.bullet.pos.y,
                    );
                    const speed = 3.0;
                    var angle = (i * (Math.PI * 2.0)) / numBullets;
                    const vel = HARNESS.planeVector2FromAngle(angle);
                    vel.multiplyScalar(speed);
                    HARNESS.setTrait(miniBullet, 'vel', vel);
                    HARNESS.deleteBullet(e.game, e.bullet);
                }
            }
        })
    });
};
