import { LemCloudApi } from "../lemCloudApi.ts";

class UploadHarnessHelper {
    private lemCloudApi: LemCloudApi;

    constructor(lemCloudApi: LemCloudApi) {
        this.lemCloudApi = lemCloudApi
    }

    getFunctionHeader = (func) => {
        // Convert the function to string and extract arguments using a regular expression
        return func.toString().match(/function\s.*?\(([^)]*)\)/)[0];
    }

    getFunctionContent(module: NodeRequire): Array<string> {
        return Object.entries(module).map(
            ([key, value]) => {
                if (typeof module[key] === 'function') {
                    return this.getFunctionHeader(value);
                }
                return null;
            }).filter(item => item !== null);
    }

    buildHarness(): string {
        let harnessContent = '';

        harnessContent += '\n/*HARNESS METHODS:*/';
        const harnessFunctions = this.getFunctionContent(require('../../../simulation/src/harness.ts'));
        harnessFunctions.forEach(x => {
            harnessContent += '\n' + x;
        })

        // harnessContent += '\n/*MATH METHODS:*/';
        // const mathFunctions = this.getFunctionContent(require('three/src/math/MathUtils'));
        // mathFunctions.forEach(x => {
        //     harnessContent += '\n' + x;
        // })

        harnessContent += '\n/*OBJECTS:*/'
        harnessContent += `
Ship:
    pos: Vector2
    rot: number`;
        harnessContent += `
Bullet:
    pos: Vector2`;
        harnessContent += `
Vector2:
    x: number
    y: number`;

        harnessContent += `
/*EVENTS*/:
onInitShip
onShoot
onUpdateShip
onUpdateBullets`;

        return harnessContent;
    }

    async uploadHarness() {
        const harnessContent = this.buildHarness();
        console.log(harnessContent);
        const result = await this.lemCloudApi.uploadHarness(
           harnessContent,
           "0.1",
           true,
        );
        if (result.error) {
            console.error(`Upload failed for harness.`, result.error);
        } else {
            console.log(`Harness uploaded successfully.`, result.data);
        }
    }
}

export { UploadHarnessHelper }