import React from 'react';

import { useBattleSequence } from '../hooks/useBattleSequence.tsx';
import { useApi } from '../hooks/useApi.tsx';

import '../styles/BattleSequence.css';
import '../styles/crt.css';
import { ShipDisplay } from './ShipDisplay.tsx';
import { useSelectedShips } from '../hooks/useSelectedShips.tsx';
import { useCurrentBattleState } from '../hooks/useCurrentBattleState.tsx';

export function BattleSequence() {
    const { isSequenceStarted, availableShips } = useBattleSequence();
    const { battleSequenceShips } = useApi();
    const { ship2 } = useSelectedShips();
    const { winner, onEndBattle } = useCurrentBattleState();

    const getBattleItemState = (ship, index) => {
        var state = ''
        if (availableShips == null || availableShips.length <= index) {
            return 'final';
        }

        if (availableShips[ship.id] === 'defeated') {
            state += 'defeated';
        } else if (ship.id === ship2) {
            state += 'active';
        }
        if (index >= battleSequenceShips.length - 1) {
            state += ' final'
        }
        return state;
    }

    const getBattleState = () => {
        return isSequenceStarted ? (winner !== null ? 'battleOver' : 'visible') : '';
    }

    const isData = () => {
        return battleSequenceShips != null && Object.keys(battleSequenceShips).length >= 5;
    }

    return (
        <div className={`crt BattleSequenceWrapper ${getBattleState()}`}>
            {isData() ? (
                <div className={`BattleSequenceData ${getBattleState()}`}>
                    <h3 className="BattleSequenceTitle">Beat them all to earn a gold star.</h3>
                    <div className={`BattleSequence ${getBattleState()}`}>
                        {battleSequenceShips.map((battleSequenceShip, index) => (
                            <>
                            <div className={`BattleSequenceItem ${getBattleItemState(battleSequenceShip, index)}`}>
                                {getBattleItemState(battleSequenceShip, index).includes('final') && !getBattleItemState(battleSequenceShip, index).includes('active') ? (
                                    <div className="UnknownShip">?</div>
                                ) : (
                                    <ShipDisplay
                                        ship={battleSequenceShip}
                                        clickHandlers={[]}
                                        isLarge
                                        showPopup={false}
                                        showOutline={false}
                                        />
                                )}
                            </div>
                            <div className={`BattleSequenceSpace ${getBattleItemState(battleSequenceShip, index)}`}/>
                            </>
                        ))}
                    </div>
                </div>
            ) : (null)}
            {winner === 0 ? (
                <div 
                    className={`ContinueButton ${getBattleState()}`}
                    onClick={onEndBattle}
                    >
                    {Object.values(availableShips).filter((v) => v !== 'defeated')
                    .length === 1 ? ("Tower Won!") : ("Next Fight")}
                </div>
            ) : null}
            {winner !== 0 ? (
                <div 
                    className={`ContinueButton ${getBattleState()}`}
                    onClick={onEndBattle}
                    >
                    Game Over
                </div>
            ) : null}
        </div>
    );
}
