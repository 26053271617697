import * as THREE from 'three';
import { randFloat } from "three/src/math/MathUtils";
import shapes from "../../../lib/shapes.ts";
import { Environment } from "../model/objects/environment.ts";
import { createOutlineMesh } from "./utils.ts";
import * as TWEEN from '@tweenjs/tween.js';
import { randInt } from '../harness.ts';
import { BattleData } from '../model/objects/battleData.ts';

class EnvironmentView {

    private isBlinking: boolean = false;

    constructor(dataManager) {
        this.dataManager = dataManager;

        this.boundaryMeshView = createOutlineMesh(shapes.unitSquare, 10, 0xFFFF44);

        var environment = this.dataManager.read(Environment);
        this.stars = []
        for (let i = 0; i < 100; ++i) {
            var rndScale = randFloat(0.01, 0.2);
            var rndRot = randFloat(0, 360);
            var rndCol = randFloat(0.01, 0.3);
            var col = new THREE.Color(rndCol, rndCol, rndCol);
            var star = createOutlineMesh(shapes.hexagram, 10, col);
            star.scale.set(rndScale, rndScale, rndScale);
            star.rotateZ(rndRot);
            star.position.set(
                randFloat(environment.boundsLeft, environment.boundsRight),
                randFloat(environment.boundsBottom, environment.boundsTop),
                0.0,
            )
            this.stars.push(star);
        }
    }

    addToScene(scene) {
        scene.add(this.boundaryMeshView);
        for (let i = 0; i < this.stars.length; ++i) {
            scene.add(this.stars[i]);

            new TWEEN.Tween(this.stars[i].scale)
                .to({
                    x: 0,
                    y: 0,
                    z: 0,
                }, randInt(100, 1000))
                // .easing(TWEEN.Easing.Linear.In)
                .delay(randInt(1000, 5000))
                .repeat(10000)
                .yoyo(true)
                .start();

        }
    }

    draw() {
        var environment = this.dataManager.read(Environment);
        var width = Math.abs(environment.boundsRight - environment.boundsLeft);
        var height = Math.abs(environment.boundsTop - environment.boundsBottom);
        this.boundaryMeshView.scale.set(width, height, 1.0);

        var battleData: BattleData = this.dataManager.read(BattleData);
        if (!this.isBlinking && battleData.timeLeftSeconds <= 0) {
            new TWEEN.Tween(this.boundaryMeshView.material.color)
                .to({
                    r: 1,
                    g: 0,
                    b: 0,
                }, 1)
                .delay(200)
                .repeat(10000)
                .yoyo(true)
                .start();
            this.isBlinking = true;
        }
    }
}

export { EnvironmentView };