class DataManager {

    private dataStorage: any;
    
    constructor() {
        this.dataStorage = {};
    }

    write(data: any) {
        this.dataStorage[data.getType()] = data;
    }

    read(type: any) {
        return this.dataStorage[type.getType()];
    }
}

export { DataManager };
