import { Game } from "../model/game";
import { Bullet } from "../model/objects/bullet";
import { Ship } from "../model/objects/ship";

class UpdateBulletsEvent {

    public game: Game;
    public ship: Ship;
    public bullet: Bullet;
    public dt: number;

    constructor(game: Game, bullet: Bullet, ship: Ship, dt: number) {
        this.game = game;
        this.ship = ship;
        this.bullet = bullet;
        this.dt = dt;
    }
}

export { UpdateBulletsEvent };
