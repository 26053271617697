import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';

import { ShipData, useApi } from '../hooks/useApi.tsx';
import { useSelectedShips } from '../hooks/useSelectedShips.tsx';

import '../styles/ShipDisplay.css';
import '../styles/crt.css';
import { useCurrentBattleState } from '../hooks/useCurrentBattleState.tsx';
import { usePlayerInfo } from '../hooks/usePlayerInfo.tsx';
import { useShipInfo } from '../hooks/useShipInfo.tsx';

type ShipDisplayClickHandler =
    | 'add-generator'
    | 'add-squad'
    | 'remove-generator'
    | 'remove-squad';

interface ShipDisplayProps {
    ship: ShipData | null;
    clickHandlers: Array<ShipDisplayClickHandler>;
    isLarge?: boolean;
    showPopup?: boolean;
    showFight?: boolean;
    showCraft?: boolean;
    showAdd?: boolean;
    invisible?: boolean;
    showOutline?: boolean;
}

export function ShipDisplay({
    ship,
    clickHandlers,
    isLarge,
    showPopup = true,
    showFight = true,
    showCraft = true,
    showAdd = false,
    invisible = false,
    showOutline = true,
}: ShipDisplayProps) {
    const {
        addShipToGenerator,
        addShipToQueue,
        removeShipFromGenerator,
        removeShipFromQueue,
        generatorShip1,
        generatorShip2,
        setNewGeneratedShip,
        newGeneratedShip,
    } = useSelectedShips();

    const { isBattleStarted } = useCurrentBattleState();
    const { postReportShip, battleSequenceShips, patchShipIsPlayable } = useApi();
    const { playerId } = usePlayerInfo();
    const {setShipInfo} = useShipInfo();

    const [isSelected, setIsSelected] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const detailsToggle = createRef();
    useEffect(() => {
        if (!isHovered && detailsToggle.current) {
            detailsToggle.current.removeAttribute("open");    
        }
    }, [isHovered])

    const polygonArgs = useMemo(() => {
        if (!ship) {
            return "";
        }

        const maxLength = Math.max(0.01, Math.max(...ship.vertices.map(([x, y]) => Math.sqrt(x * x + y * y))));
        const remappedVertices = ship?.vertices.map(
            (coordinatePair) => {
                // Mapping [0, 1] ranged coordinates to the SVG dimensions
                // If the SVG dimensions are 100x100, you do not need to scale by 100 or add 50 like in the CSS version
                const scale = 0.45
                let x = coordinatePair[0] / maxLength * 100 * scale + 50;
                let y = coordinatePair[1] / maxLength * 100 * scale + 50;
                return `${x},${y}`;
            }
        );
        
        // Join all the point strings with a space
        return remappedVertices?.join(' ');
    }, [ship?.vertices]);

    const canGenerate = () => {
        return generatorShip1 !== ship?.id 
            && generatorShip2 !== ship?.id
            && (generatorShip1 === '' || generatorShip2 === '')
    }

    function scrollToElementWithinParent(childId, parentId) {
        const childElement = document.getElementById(childId);
        const parentElement = document.getElementById(parentId);
        
        if (childElement && parentElement) {
            // Get the child's position relative to the parent
            const childRect = childElement.getBoundingClientRect();
            const parentRect = parentElement.getBoundingClientRect();
            
            // Calculate the scroll position needed
            // const scrollPosition = childElement.offsetTop - parentElement.offsetTop - (parentRect.height / 2 - childRect.height / 2);
            // const scrollPosition = childElement.offsetTop + parentElement.offsetTop + childRect.height / 2;
            const scrollPosition = childRect.top - childRect.height * 3;
    
            // Scroll the parent element smoothly
            parentElement.scroll({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    }

    const onAddButtonClick = () => {
        setNewGeneratedShip('');
        removeShipFromGenerator(generatorShip1);
        removeShipFromGenerator(generatorShip2);
    }

    const reportButtonClick = () => {
        if (ship) {
            postReportShip(ship.id, playerId);
            alert("Report Sent!");
        }
    }

    const toggleStartClick = () => {

    }

    const showCrowned = () => {
        if (ship) {
            return ship.crowned && !ship.isStarting;
        } return false;
    }

    const isOwned = () => {
        if (ship) {
            return ship.creatorId === playerId
        } return false;
    }

    const isBattleSequenceData = () => {
        return battleSequenceShips != null && Object.keys(battleSequenceShips).length >= 5;
    }
    
    const togglePlayable = () => {
        if (ship) {
            ship.isPlayable = !ship?.isPlayable;
            patchShipIsPlayable(ship.id, ship.isPlayable);
        }
    }

    const onHover = useCallback((isHovered: boolean) => () => {
        setShipInfo(isHovered ? ship : null)
        setIsHovered(isHovered);
    }, [setShipInfo, ship])

    return (
        <div className={`ShipDisplayWrapper ${isLarge ? 'large' : ''} ${ship != null && !invisible && showOutline ? 'hoverable' : ''} ${ship?.isPlayable || !ship ? '' : 'broken'}`}
            onMouseEnter={onHover(true)}
            onMouseLeave={onHover(false)}
            id={`${ship?.id}`}
        >
            {ship == null || invisible ? null : (
                <>
                    {clickHandlers.includes('remove-squad') ||
                    clickHandlers.includes('remove-generator') ? (
                        <div
                            className="ShipDisplayIcon"
                            onClick={() => {
                                if (clickHandlers.includes('remove-squad')) {
                                    removeShipFromQueue(ship.id);
                                }
                                if (
                                    clickHandlers.includes('remove-generator')
                                ) {
                                    removeShipFromGenerator(ship.id);
                                }
                            }}
                        >
                            {'x'}
                        </div>
                    ) : null}
                    <div
                        className="ShipDisplay"
                        onClick={() => setIsSelected(!isSelected)}
                    >
                        <div className={`BrokenText ${ship.isPlayable ? '' : 'broken'}`}>BROKEN</div>
                        <div className="ShipMedals">
                            {showCrowned() ? (
                                <svg className={`ShipMedal Owned ${isHovered ? 'hovered' : ''}`}>
                                    <polygon className='MedalPoly' points="50,10 60,40 90,40 65,60 75,90 50,70 25,90 35,60 10,40 40,40" stroke='gold' fill="gold" strokeWidth="5" />
                                </svg>
                            ) : (null)}
                            {isOwned() ? (
                                <svg className={`ShipMedal Crown ${isHovered ? 'hovered' : ''}`}>
                                    <polygon className='MedalPoly' points="50,10 60,40 90,40 65,60 75,90 50,70 25,90 35,60 10,40 40,40" stroke='turquoise' fill="turquoise" strokeWidth="5" />
                                </svg>
                            ) : (null)}
                        </div>
                        <svg className={`ShipDisplayPolygon ${isHovered ? 'hovered' : ''}`} width="100" height="100" style={{ margin: 'auto', display: 'block' }}>
                            <polygon points={polygonArgs} stroke={`#${ship.aesthetics.color}`} fill="none" strokeWidth="5" />
                        </svg>
                    </div>
                    {true && (
                        <div className={`ShipPopupWrapper ${isHovered && showPopup ? 'visible' : ''} crt`}>
                            <div className="PopupOptions">
                                {showFight ? (
                                    <div className={`PopupButton ${isBattleStarted || !isBattleSequenceData() || !ship.isPlayable ? 'Disabled' : ''}`}
                                        onClick={() => !isBattleStarted && ship.isPlayable ? addShipToQueue(ship.id) : null}
                                    >Play</div>
                                ) : (null)}
                                {showCraft ? (
                                    <div className={`PopupButton ${!canGenerate() || !ship.isPlayable ? 'Disabled' : ''}`}
                                        onClick={() => canGenerate() && ship.isPlayable ? addShipToGenerator(ship.id) : null}
                                    >Craft</div>
                                ) : (null)}
                                {showAdd ? (
                                    <div className={`PopupButton`}
                                        onClick={onAddButtonClick}
                                    >Add To Hangar</div>
                                ) : (null)}
                            </div>
                            <div className="PopupOptions">
                                {process.env.REACT_APP_IS_DEBUG == '1' ? (
                                    <div className={`PopupButton Report`}
                                        onClick={() => togglePlayable()}
                                    >{ship.isPlayable ? 'Broken' : 'Playable'}</div>
                                ) : (null)}
                                {showFight ? (
                                    <div className={`PopupButton Report`}
                                        onClick={() => reportButtonClick()}
                                    >Report</div>
                                ) : (null)}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
