import { ship0, shipDescrip0 } from '../simulation/src/examples/ship0.ts';
import { ship1, shipDescrip1 } from '../simulation/src/examples/ship1.ts';
import { ship2, shipDescrip2 } from '../simulation/src/examples/ship2.ts';
import { ship3, shipDescrip3 } from '../simulation/src/examples/ship3.ts';
import { ship4, shipDescrip4 } from '../simulation/src/examples/ship4.ts';
import { ship5, shipDescrip5 } from '../simulation/src/examples/ship5.ts';
import { ship6, shipDescrip6 } from '../simulation/src/examples/ship6.ts';
import { ship7, shipDescrip7 } from '../simulation/src/examples/ship7.ts';
import { ship8, shipDescrip8 } from '../simulation/src/examples/ship8.ts';

import shapes from '../lib/shapes.ts';

export const EXAMPLE_SHIPS = [
    {
        name: 'SHOOTY',
        color: 'FF0000', // red
        vertices: shapes.triangle,
        eventHandlers: ship0,
        description: shipDescrip0,
        isStarting: true,
    },
    {
        name: 'SCAREDY',
        color: '0000FF', // blue
        vertices: shapes.square,
        eventHandlers: ship1,
        description: shipDescrip1,
        isStarting: false,
    },
    {
        name: 'GLOBBY',
        color: '008000', // green
        vertices: shapes.pentagram,
        eventHandlers: ship2,
        description: shipDescrip2,
        isStarting: true,
    },
    {
        name: 'ZOOMY',
        color: 'FFFF00', // yellow
        vertices: shapes.hexagon,
        eventHandlers: ship3,
        description: shipDescrip3,
        isStarting: false,
    },
    {
        name: 'JUMPY',
        color: 'FFFFFF', // white
        vertices: shapes.oblong,
        eventHandlers: ship4,
        description: shipDescrip4,
        isStarting: false,
    },
    {
        name: 'CLONKY',
        color: '800080', // purple
        vertices: shapes.arrow,
        eventHandlers: ship5,
        description: shipDescrip5,
        isStarting: false,
    },
    {
        name: 'BOUNCY',
        color: 'FFC0CB', // pink
        vertices: shapes.diamond,
        eventHandlers: ship6,
        description: shipDescrip6,
        isStarting: false,
    },
    {
        name: 'BUZZY',
        color: '008080', // teal
        vertices: shapes.asymmetric,
        eventHandlers: ship7,
        description: shipDescrip7,
        isStarting: true,
    },
    {
        name: 'SNAKEY',
        color: 'A52A2A', // brown
        vertices: shapes.crescent,
        eventHandlers: ship8,
        description: shipDescrip8,
        isStarting: false,
    },
] as const;