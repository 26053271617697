import { Vector2 } from 'three';

class Bullet {
    static TYPE = "Bullet";

    public scale: number;
    public id: number;
    public shipId: number;
    public pos: Vector2;
    public traits: Record<string, any>;

    constructor(scale: number, id: number, shipId: number) {
        this.scale = scale;
        this.id = id;
        this.shipId = shipId;
        this.pos = new Vector2();
        this.traits = {};

        // Safety system for field-based trait access.
        return new Proxy(this, {
            get: (target, prop: any, receiver) => {
              if (prop in target) {
                return Reflect.get(target, prop, receiver);
              }
              return target.traits[prop];
            },
            set: (target, prop: any, value, receiver) => {
              if (prop in target) {
                Reflect.set(target, prop, value, receiver);
              } else {
                target.traits[prop] = value;
              }
              return true;
            }
        });
    }

    getType() { return Bullet.TYPE; }
    static getType() { return Bullet.TYPE; }
}

export { Bullet };
