import { Game } from "../model/game";
import { Ship } from "../model/objects/ship";

class UpdateShipEvent {

    public game: Game;
    public ship: Ship;
    public dt: number;

    constructor(game: Game, ship: Ship, dt: number) {
        this.game = game;
        this.ship = ship;
        this.dt = dt;
    }
}

export { UpdateShipEvent };
