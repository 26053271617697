import { Color, Vector3 } from "three";
import { Game } from "../model/game.ts";
import { createCssLabel } from "./utils.ts";
import { CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import * as TWEEN from '@tweenjs/tween.js';
import { GameView } from "./gameView.ts";
import { BattleData } from "../model/objects/battleData.ts";

class HudView {

    private game: Game;
    private gameView: GameView;
    private startCountDown: Array<CSS2DObject>;
    private battleTimer: CSS2DObject;
    private battleTimerWarning: boolean;
    private battleTimerColor: Color;
    private battleTimerFontSize: object;

    constructor(game: Game, gameView: GameView) {

        this.game = game;
        this.gameView = gameView;

        this.onTransition = this.onTransition.bind(this);
        this.update = this.update.bind(this);
        game.stateHandler.eventHandler.addEventToSet("transition", this.onTransition);
        game.gameEventHandlers.addEventToSet("update", this.update);

        this.startCountDown = []
        for (let i = 0; i < 4; ++i) {
            if (i == 3) {
                this.startCountDown.push(createCssLabel("FIGHT!"));
            } else {
                this.startCountDown.push(createCssLabel(3 - i));
            }
            this.startCountDown[i].element.style.fontSize = "0px";
            this.startCountDown[i].position.set(0, 0, 0);
        }

        this.battleTimer = createCssLabel("00:00");
        this.battleTimer.element.style.fontSize = "40px";
        this.battleTimer.position.set(0, 5, 0);
        this.battleTimerWarning = false;
    }

    addToScene(scene) {
        for (let i = 0; i < 4; ++i) {
            scene.add(this.startCountDown[i]);
        }
        scene.add(this.battleTimer);
    }

    cleanup(scene) {
        for (let i = 0; i < 4; ++i) {
            scene.remove(this.startCountDown[i]);
        }
        scene.remove(this.battleTimer);
    }

    onTransition(newState: string) {
        if (newState == "start") {
            this.initStartState();
        } else if (newState == "play") {
            this.initPlayState();
        }
    }

    update() {
        if (this.game.stateHandler.getCurrentState() == "start") {
        } else if (this.game.stateHandler.getCurrentState() == "play") {
            this.updatePlayState();
        }

        this.battleTimer.position.set(
            this.gameView.camera.position.x,
            this.gameView.camera.position.y + 7,
            0.0,
        )
    }

    initStartState() {
        var pacing = 800;
        for (let i = 0; i < this.startCountDown.length; ++i) {
            this.startCountDown[i].size = 0;
            this.startCountDown[i].opacity = 1;
            new TWEEN.Tween(this.startCountDown[i])
                .to({
                    size: 150,
                }, i < 3 ? pacing : pacing * 0.5)
                .delay(i * (pacing * 1.2))
                .onUpdate((x, s) => x.element.style.fontSize = x.size + "px")
                .start()
                .onComplete(() => {
                    new TWEEN.Tween(this.startCountDown[i])
                    .to({
                        opacity: 0
                    }, 100)
                    .onUpdate((x, s) => x.element.style.opacity = x.opacity + "")
                    .start();
                });
        }
    }

    initPlayState() {
        this.battleTimer.position.set(
            this.gameView.camera.position.x,
            this.gameView.camera.position.y,
            0.0,
        )
    }

    updatePlayState() {
        var battleData = this.game.dataManager.read(BattleData);
        var seconds = Math.floor(battleData.timeLeftSeconds);
        var milliseconds = ("" + (battleData.timeLeftSeconds % 1) * 100).slice(0, 2);
        this.battleTimer.element.textContent = `${seconds}:${milliseconds}`;

        if(!this.battleTimerWarning && battleData.timeLeftSeconds < 10) {
            this.battleTimerColor = new Color(1, 1, 1);
            this.battleTimerFontSize = { size: 40 };
            var duration = 200;
            var delay = 200;
            new TWEEN.Tween(this.battleTimerColor)
                .to({
                    r: 1,
                    g: 0,
                    b: 0,
                }, duration)
                .onUpdate((x) => {
                    this.battleTimer.element.style.color = "#" + x.getHexString();
                })
                .delay(delay)
                .repeat(10000)
                .yoyo(true)
                .start();
            new TWEEN.Tween(this.battleTimerFontSize)
                .to({
                    size: 50,
                }, duration)
                .onUpdate((x) => {
                    this.battleTimer.element.style.fontSize = `${x.size}px`;
                })
                .delay(delay)
                .repeat(1000)
                .yoyo(true)
                .start();
            this.battleTimerWarning = true;
        }

        if (battleData.timeLeftSeconds <= 0) {
            this.battleTimer.element.textContent = "!WARNING!";
        }
    }
}

export { HudView }