
export function checkCollisionCircles(circleA, circleB) {
    var dX = circleA.pos.x - circleB.pos.x;
    var dY = circleA.pos.y - circleB.pos.y;
    var dist = Math.sqrt(dX*dX + dY*dY);
    return dist < 1.0;
}

export function transformVertices(vertices, rotationInRadians, position) {
    // Calculate the sine and cosine of the rotation angle once
    const cosTheta = Math.cos(rotationInRadians);
    const sinTheta = Math.sin(rotationInRadians);

    // Transform the vertices
    const transformedVertices = vertices.map(vertex => {
        // Apply rotation
        const rotatedX = vertex[0] * cosTheta - vertex[1] * sinTheta;
        const rotatedY = vertex[0] * sinTheta + vertex[1] * cosTheta;

        // Apply translation (position)
        const translatedX = rotatedX + position[0];
        const translatedY = rotatedY + position[1];

        return [translatedX, translatedY];
    });

    return transformedVertices;
}

export function pointInsidePolygon(point, polygon) {
    let collision = false;
    for(let i=0, j=polygon.length-1; i < polygon.length; j=i++) {
        if(((polygon[i][1] > point[1]) != (polygon[j][1] > point[1])) &&
        (point[0] < (polygon[j][0]-polygon[i][0]) * (point[1]-polygon[i][1]) / (polygon[j][1]-polygon[i][1]) + polygon[i][0])) {
            collision = !collision;
        }
    }
    return collision;
}

export function findClosestPointOnEdge(circle, edgeStart, edgeEnd) {
    const edgeLength = Math.hypot(edgeEnd[0] - edgeStart[0], edgeEnd[1] - edgeStart[1]);
    const t = ((circle[0] - edgeStart[0]) * (edgeEnd[0] - edgeStart[0]) + (circle[1] - edgeStart[1]) * (edgeEnd[1] - edgeStart[1])) / (edgeLength ** 2);
    
    const tClamped = Math.max(0, Math.min(1, t)); // Ensure t is within [0,1]
    
    // Compute the closest point using tClamped
    return [
        edgeStart[0] + tClamped * (edgeEnd[0] - edgeStart[0]),
        edgeStart[1] + tClamped * (edgeEnd[1] - edgeStart[1])
    ];
}

export function edgeCircleCollision(circlePos, circleRadius, edgeStart, edgeEnd) {
    const closestPoint = findClosestPointOnEdge(circlePos, edgeStart, edgeEnd);
    const distanceToCircle = Math.hypot(closestPoint[0] - circlePos[0], closestPoint[1] - circlePos[1]);
    return distanceToCircle < circleRadius;
}

export function shipBulletCollision(shipVertices, bulletPos, bulletRadius) {
    // Check if any of the ship's edges collides with the bullet
    for(let i=0, j=shipVertices.length-1; i < shipVertices.length; j=i++) {
        if(edgeCircleCollision(bulletPos, bulletRadius, shipVertices[i], shipVertices[j])) {
            return true;
        }
    }

    // Check if the bullet is inside the ship polygon
    if(pointInsidePolygon(bulletPos, shipVertices)) {
        return true;
    }
    
    return false;
}