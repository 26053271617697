import { LemCloudApi } from "../lemCloudApi.ts";
import { EXAMPLE_SHIPS } from "../../../lib/ships.ts";
import { ShipData } from "../../../ui/hooks/useApi.tsx";

class UploadExamplesHelper {
    private lemCloudApi: LemCloudApi;

    constructor(lemCloudApi: LemCloudApi) {
        this.lemCloudApi = lemCloudApi
    }

    async uploadAllShips(shipToLemConverter) {
        for (const ship of EXAMPLE_SHIPS) {
            const shipData: ShipData = {
                name: ship.name,
                aesthetics: {
                    color: ship.color,
                },
                vertices: ship.vertices,
                eventHandlers: ship.eventHandlers.toString(),
                description: ship.description,
                isStarting: ship.isStarting,
            };
            const lemShipData = shipToLemConverter(shipData, '0');
            console.log(shipData);

            const result = await this.lemCloudApi.uploadShipMethod(lemShipData);
            if (result.error) {
                console.error(`Upload failed for ship: ${ship.name}`, result.error);
            } else {
                console.log(`Ship uploaded successfully: ${ship.name}`, result.data);
            }
        }
    }
}

export { UploadExamplesHelper }