import React, { useState, useEffect } from 'react';
import { usePlayerInfo } from '../hooks/usePlayerInfo.tsx';
import '../styles/PlayerInfo.css';
import { useApi } from '../hooks/useApi.tsx';
import { useGameState } from '../hooks/useGameState.tsx';

interface PlayerInfoProps {
    interactable: boolean;
    loginButton?: boolean;
}

export function PlayerInfo({ 
    interactable = true,
    loginButton = true,
}: PlayerInfoProps) {
    const { getAllShips } = useApi();
    const { playerId, setPlayerId, inputPlayerId, setInputPlayerId } = usePlayerInfo();
    const { isPlaying } = useGameState();

    useEffect(() => {
        if (playerId !== '' && isPlaying) {
            getAllShips(playerId);
        }
    }, [getAllShips, playerId, isPlaying]);

    useEffect(() => {
        if (playerId !== '') {
            setInputPlayerId(playerId);
        }
    }, [playerId])

    const handleInputChange = (e) => {
        const newValue = e.target.value.trim();
        if (loginButton) {
            setInputPlayerId(newValue);
        } else {
            setInputPlayerId(newValue);
            if (newValue) {
                setPlayerId(newValue);
            }
        }
    };

    const handleSubmit = () => {
        // Validate and set player ID, then you could call the API
        if (inputPlayerId.trim()) {
            setPlayerId(inputPlayerId);
        }
    };

    const isDirty = () => {
        return inputPlayerId != playerId;
    }

    const isInvalid = () => {
        return !inputPlayerId
    }

    return (
        <div className="PlayerInfo">
            <div className="PlayerInfoId">
                <div className="PlayerInfoHeader">Player Id:</div>
                <input
                    type="text"
                    value={interactable ? inputPlayerId : playerId}
                    onChange={handleInputChange}
                    className={`PlayerInput ${!interactable ? 'disabled' : ''} ${isDirty() ? 'dirty' : ''} ${isInvalid() ? 'invalid' : ''}`}
                    disabled={!interactable}
                />
            </div>
            {interactable && loginButton ? (
                <button onClick={handleSubmit} className={`SetButton ${isDirty() && !isInvalid() ? '' : 'disabled'}`}>
                    Change Player
                </button>
            ) : null}
        </div>
    );
}
