import { Vector2 } from "three";
import { Game } from "../model/game.ts";
import { ShipCollection } from "../model/objects/shipCollection.ts";
import TWEEN from '@tweenjs/tween.js'

class AnimateEnterSystem {

    private game: Game;
    private allDoneCount: number;

    constructor(game: Game) {
        this.game = game;
        this.allDoneCount = 0;

        this.update = this.update.bind(this);
    }

    attach() {
        this.game.gameEventHandlers.addEventToSet("update", this.update);

        this.init();
    }

    detach() {
        this.game.gameEventHandlers.removeEventFromSet("update", this.update);
    }

    init()  {
        var ships = this.game.dataManager.read(ShipCollection).ships;
        var index = 0;
        for (var key in ships) {
            var ship = ships[key];
            ship.pos.add(new Vector2(-10 + 20 * index, 0));
            new TWEEN.Tween(ship.pos)
                .to({
                    x: -4 + 8 * index++,
                    y: 0,
                }, 2000 + Math.random() * 200)
                .delay(0 + index * 800)
                .easing(TWEEN.Easing.Exponential.Out)
                .start();
            new TWEEN.Tween(ship)
                .to({
                    scale: 0.7
                }, 200)
                .delay(3000)
                .easing(TWEEN.Easing.Back.Out)
                .start()
                .onComplete((x) => {
                    new TWEEN.Tween(x)
                        .to({
                            scale: 1.0
                        }, 200)
                        .easing(TWEEN.Easing.Back.In)
                        .start()
                        .onComplete(() => {
                            this.allDoneCount++;
                        });
                });

        }
    }

    update(dt) {
        this.checkDone();
    }

    checkDone() {
        var ships = this.game.dataManager.read(ShipCollection).ships;

        if (this.allDoneCount >= Object.entries(ships).length) {
            this.game.stateHandler.transition("play");
        }
    }
}

export { AnimateEnterSystem }