import React, { useEffect, useRef } from 'react';

import main from '../../simulation/src/main.ts';
import screenMain from '../../simulation/src/screenIdleView.ts'

import { useCurrentBattleState } from '../hooks/useCurrentBattleState.tsx';
import { useSelectedShips } from '../hooks/useSelectedShips.tsx';

import '../styles/BattleSimulator.css';
// import '../styles/crt.css';
import { useApi } from '../hooks/useApi.tsx';
import { useBattleSequence } from '../hooks/useBattleSequence.tsx';

export function BattleSimulator() {
    const { isBattleStarted, setWinner, speed } = useCurrentBattleState();
    const { ship1, ship2 } = useSelectedShips();
    const { allShips, battleSequenceShips } = useApi();
    const { availableShips } = useBattleSequence();

    const ref = useRef(null);
    const refLabel = useRef(null);

    useEffect(() => {
        if (isBattleStarted && ship1 !== '' && ship2 !== '') {
            var ship2Data;
            for (var i = 0; i < battleSequenceShips.length; ++i) {
                if (battleSequenceShips[i].id == ship2) {
                    ship2Data = battleSequenceShips[i];
                }
            }
            if (!ship2Data) {
                console.error(`SHIP2 UNDEFINED SHIP: ${ship2}`);
            }
            main(ref, refLabel, [allShips[ship1], ship2Data], setWinner, speed);
            // screenMain(ref);
        }
    }, [isBattleStarted, setWinner, ship1, ship2, speed, battleSequenceShips]);

    useEffect(() => {
        if (!isBattleStarted) {
            if (ref.current?.childElementCount > 0) {
                ref.current?.removeChild(ref.current?.firstChild);
            }

            if (refLabel.current?.childElementCount > 0) {
                refLabel.current?.removeChild(refLabel.current?.firstChild);
            }
        }
    }, [isBattleStarted]);

    const isData = () => {
        return battleSequenceShips != null && Object.keys(battleSequenceShips).length >= 5;
    }

    // TODO: Avoid adding multiple 3js canvas children to the ref initially
    // rather than retroactively removing duplicates because
    // USING VANILLA JS IN REACT LIKE THIS IS A TERRIBLE PRACTICE
    if (ref.current?.childElementCount > 1) {
        ref.current?.removeChild(ref.current?.firstChild);
    }

    if (refLabel.current?.childElementCount > 1) {
        refLabel.current?.removeChild(refLabel.current?.firstChild);
    }

    return (
        <div className="SimulatorContainer">
            {isData() ? (
                <>
                <div className="BattleSimulatorWrapper" ref={ref}/>
                <div ref={refLabel}/>
                </>
            ) : (
                <h2>The battle channel will be back soon...</h2>
            )}
        </div>
    );
}
