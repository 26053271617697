import { UpdateBulletsEvent } from "../events/updateBulletsEvent.ts";
import { UpdateShipEvent } from "../events/updateShipEvent.ts";
import { Game } from "../model/game.ts";
import { BulletCollection } from "../model/objects/bulletCollection.ts";
import { ShipCollection } from "../model/objects/shipCollection.ts";

class UpdateSystem {

    private game: Game;

    constructor(game: Game) {
        this.game = game;

        this.update = this.update.bind(this);
    }

    attach() {
        this.game.gameEventHandlers.addEventToSet('update', this.update);
    }

    detach() {
        this.game.gameEventHandlers.removeEventFromSet('update', this.update);
    }

    update(dt: number) {
        var shipCollection = this.game.dataManager.read(ShipCollection);
        for (var key in shipCollection.ships) {
            var ship = shipCollection.ships[key];
            ship.eventHandler.callAllEventsInSet(
                'onUpdateShip',
                new UpdateShipEvent(this.game, ship, dt)
            );
        }
        var bulletCollection = this.game.dataManager.read(BulletCollection);
        for (var key in bulletCollection.bullets) {
            var bullet = bulletCollection.bullets[key];
            if (bullet.shipId in shipCollection.ships)
            {
                var ship = shipCollection.ships[bullet.shipId];
                ship.eventHandler.callAllEventsInSet(
                    'onUpdateBullets',
                    new UpdateBulletsEvent(this.game, bullet, ship, dt)
                );
            }
            else
            {
                this.game.removeBullet(bullet);
            }
        }
    }
}

export { UpdateSystem }