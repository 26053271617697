import { Timer } from "./timer";

class TimersCollection {
    static TYPE = "TimerCollection";

    public timers: Record<number, Timer>;
    public nextId: number;

    constructor() {
        this.timers = {};
        this.nextId = 0;
    }

    getType() { return TimersCollection.TYPE; }
    static getType() { return TimersCollection.TYPE; }
}

export { TimersCollection }