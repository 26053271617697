import { State } from "../utils/stateHandler.ts";
import { Game } from "./game.ts";

class DeathState extends State {

    constructor(game: Game) {
        super();
    }
}

export { DeathState }