const triangle = [
    [0, 0.577], // Top (using the property of equilateral triangle height)
    [-0.5, -0.289], // Bottom left
    [0.5, -0.289] // Bottom right
];

const square = [
    [0.707, 0.707], // Top right
    [-0.707, 0.707], // Top left
    [-0.707, -0.707], // Bottom left
    [0.707, -0.707] // Bottom right
];

const unitSquare = [
    [0.5, 0.5], // Top right
    [-0.5, 0.5], // Top left
    [-0.5, -0.5], // Bottom left
    [0.5, -0.5] // Bottom right
];

const pentagram = [
    [0, 0.5], // Top
    [-0.4755, 0.1545], // Bottom left
    [0.4755, 0.1545], // Bottom right
    [-0.293, -0.4045], // Middle left
    [0.293, -0.4045] // Middle right
];

const hexagon = [
    [0.5, 0], // Right
    [0.25, 0.433], // Top right
    [-0.25, 0.433], // Top left
    [-0.5, 0], // Left
    [-0.25, -0.433], // Bottom left
    [0.25, -0.433] // Bottom right
];

const oblong = [
    [0, 0.5], // Top
    [0.25, 0.433],
    [0.433, 0.25],
    [0.5, 0], // Right
    [0.433, -0.25],
    [0.25, -0.433],
    [0, -0.5], // Bottom
    [-0.25, -0.433],
    [-0.433, -0.25],
    [-0.5, 0], // Left
    [-0.433, 0.25],
    [-0.25, 0.433]
];

const arrow = [
    [0, 0.5], // Tip
    [-0.2, 0.3],
    [-0.5, 0.3],
    [-0.5, 0],
    [-0.5, -0.3],
    [-0.2, -0.3],
    [0, -0.2], // Tail top
    [0, 0.2]
];

const diamond = [
    [0, 0.5], // Top
    [0.5, 0], // Right
    [0, -0.5], // Bottom
    [-0.5, 0] // Left
];

const asymmetric = [
    [-0.1, 0.5], // Top
    [0.4, 0.2],
    [0.5, 0], // Right
    [0.3, -0.3],
    [-0.5, -0.2], // Left
    [-0.3, 0.3]
];

const crescent = [
    [0, 0.5], // Top middle
    [0.25, 0.433],
    [0.433, 0.25],
    [0.5, 0],
    [0.433, -0.25],
    [0.25, -0.433],
    [0, -0.5], // Bottom middle
    [-0.25, -0.433],
    [-0.4, -0.3],
    [-0.5, 0],
    [-0.4, 0.3],
    [-0.25, 0.433]
];

const chevron = [
    [0.5, 0.1], // Right bottom
    [0.15, 0.1], // Middle bottom right
    [0, 0.5], // Top center
    [-0.15, 0.1], // Middle bottom left
    [-0.5, 0.1], // Left bottom
    [-0.15, -0.1], // Middle top left
    [0, -0.5], // Bottom center
    [0.15, -0.1] // Middle top right
];

const hexagram = [
    [0, 0.5], // Top of first triangle
    [-0.433, -0.25], // Moving to bottom left of first triangle
    
    [-0.433, -0.25], // Bottom left of first triangle
    [0.433, -0.25], // Moving to bottom right of first triangle
    
    [0.433, -0.25], // Bottom right of first triangle
    [0, 0.5], // Closing first triangle
    
    // Start of the second triangle
    [0, -0.5], 
    [0.433, 0.25],
    
    [0.433, 0.25],
    [-0.433, 0.25],
    
    [-0.433, 0.25],
    [0, -0.5]
];

const shapes = {
    triangle,
    square,
    unitSquare,
    pentagram,
    hexagon,
    oblong,
    arrow,
    diamond,
    asymmetric,
    crescent,
    chevron,
    hexagram,
};
export default shapes;