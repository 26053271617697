import React from 'react';

import { SelectedShipsProvider } from '../hooks/useSelectedShips.tsx';
import { CurrentBattleStateProvider } from '../hooks/useCurrentBattleState.tsx';
import { BattleSequenceProvider } from '../hooks/useBattleSequence.tsx';
import { ApiProvider } from '../hooks/useApi.tsx';
import { GameStateProvider } from '../hooks/useGameState.tsx';
import { ShipInfoProvider } from '../hooks/useShipInfo.tsx';

import { BattleSimulator } from './BattleSimulator.tsx';
import { ShipHangar } from './ShipHangar.tsx';
import { PlayerInfo } from './PlayerInfo.tsx';
import { Navigation } from './Navigation.tsx';
import { BattleSequence } from './BattleSequence.tsx';
import { Splashscreen } from './Splashscreen.tsx';
import { ShipInfo } from './ShipInfo.tsx';

import '../styles/App.css';
import '../styles/crt.css';
import { PlayerInfoProvider } from '../hooks/usePlayerInfo.tsx';

export function App() {
    return (
        <ApiProvider>
            <GameStateProvider>
            <PlayerInfoProvider>
                <SelectedShipsProvider>
                    <BattleSequenceProvider>
                        <CurrentBattleStateProvider>
                            <ShipInfoProvider>
                            <div className="AppWrapper crt">
                                <Navigation />
                                <div className="BodyWrapper">
                                    <div className="BattleSquadWrapper">
                                        <div className="BattleWrapper">
                                            <BattleSimulator />
                                            {/* <HUDOverlay /> */}
                                            <BattleSequence />
                                            <ShipInfo />
                                        </div>
                                        {/* <ShipSquad /> */}
                                    </div>
                                    <div className="Sidebar">
                                        <PlayerInfo interactable={false} />
                                        <ShipHangar />
                                    </div>
                                </div>
                                <Splashscreen />
                            </div>
                            </ShipInfoProvider>
                        </CurrentBattleStateProvider>
                    </BattleSequenceProvider>
                </SelectedShipsProvider>
            </PlayerInfoProvider>
            </GameStateProvider>
        </ApiProvider>
    );
}
