import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSelectedShips } from '../hooks/useSelectedShips.tsx';
import { ShipData, useApi } from '../hooks/useApi.tsx';

import { ShipDisplay } from './ShipDisplay.tsx';

import '../styles/ShipDisplay.css';
import '../styles/ShipHangar.css';
import { usePlayerInfo } from '../hooks/usePlayerInfo.tsx';

export function ShipHangar() {
    const {
        generatorShip1,
        generatorShip2,
        removeShipFromGenerator,
        newGeneratedShip,
        setNewGeneratedShip,
    } = useSelectedShips();
    const { allShips, generateShip } = useApi();
    const { playerId } = usePlayerInfo();

    const hangarEndRef = useRef(null);

    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [generateInfo, setGenerateInfo] = useState<string>("");

    useEffect(() => {
        if (Object.keys(allShips).length > 0) {
            hangarEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [allShips]);

    const onGenerateShip = useCallback(async () => {
        if (generatorShip1 !== '' && generatorShip2 !== '') {
            let shipId = '';
            // TEMP: special case if same ship selected twice: just run the ship.
            if (generatorShip1 === generatorShip2) {
                shipId = generatorShip1;
            } else {
                setGenerateInfo(null);
                setIsGenerating(true);
                var response = await generateShip(
                    generatorShip1,
                    generatorShip2,
                    playerId
                );
                shipId = response.shipId;
                setNewGeneratedShip(shipId);
                setIsGenerating(false);
                setGenerateInfo(response.info);
            }
        }
    }, [generateShip, generatorShip1, generatorShip2, removeShipFromGenerator]);

    useEffect(() => {
        if (generatorShip1 !== '' && generatorShip2 !== '') {
            onGenerateShip();
        }
    }, [generatorShip1, generatorShip2]);

    useEffect(() => {
        if (generateInfo && generateInfo.error) {
            removeShipFromGenerator(generatorShip2);
        }
    }, [generateInfo, generatorShip2]);

    useEffect(() => {
        if (newGeneratedShip == '') {
            setGenerateInfo(null);
        }
    }, [setGenerateInfo, newGeneratedShip]);

    const isData = () => {
        return allShips != null && Object.keys(allShips).length >= 3;
    }

    return (
        <div className="ShipHangarWrapper">
            <div className="ShipHangarOptionContainer">
                <div className="ShipHangarTitle">
                    <h2>HANGAR</h2>
                    <p>Play a ship or craft a new one!</p>
                </div>
                {isData() ? (
                    <div className="ShipHangarOptionList" id="ShipHangarScroll">
                        {Object.keys(allShips).map((index) => {
                            const ship = allShips[index] as ShipData;
                            return (
                                <ShipDisplay
                                    key={ship.name}
                                    ship={ship}
                                    clickHandlers={['add-squad', 'add-generator']}
                                    invisible={
                                        newGeneratedShip !== '' &&
                                        ship.id === newGeneratedShip
                                    }
                                />
                            );
                        })}
                        <div ref={hangarEndRef} />
                    </div>
                ) : (
                    <div className="ShipHangarOptionList" id="ShipHangarScroll">
                        <h2>Your ship hangar is loading...</h2>
                    </div>
                )}
                <div className="ShipHangarShadow"></div>
            </div>
            <div className="ShipGenerator">
                <>
                    <div className="ShipHangarTitle">
                        <h2>CRAFT</h2>
                    {generateInfo ? (
                            <p
                                className={`CraftingResponseLog ${generateInfo !== '' ? 'visible' : ''} ${generateInfo.type}`}
                            >
                                <span>{`${generateInfo.type}: `}</span>{generateInfo.message}
                            </p>
                    ) : (null)}
                    </div>
                    <div
                        className={`GeneratorComplete ${
                            newGeneratedShip !== '' ? 'visible' : ''
                        }`}
                    >
                        <ShipDisplay
                            ship={allShips[newGeneratedShip]}
                            clickHandlers={[]}
                            isLarge
                            showPopup={true}
                            showAdd={true}
                            showCraft={false}
                            showFight={false}
                        />
                    </div>
                    <div
                        className={`GeneratorComponents ${
                            newGeneratedShip !== '' ? 'invisible' : ''
                        }`}
                    >
                        <div
                            className={`LoadingText ${
                                isGenerating ? 'visible' : ''
                            }`}
                        >
                            Crafting...
                        </div>
                        <div
                            className={`GeneratorSlot ${
                                isGenerating || newGeneratedShip !== ''
                                    ? 'left'
                                    : ''
                            } ${generatorShip1 == '' ? 'empty' : ''}`}
                        >
                            <ShipDisplay
                                ship={allShips[generatorShip1] ?? null}
                                clickHandlers={
                                    isGenerating ? [] : ['remove-generator']
                                }
                                isLarge
                                showPopup={!isGenerating}
                                showOutline={false}
                            />
                        </div>
                        {!isGenerating ? (
                            <div className="ShipText large">+</div>
                        ) : null}
                        <div
                            className={`GeneratorSlot ${
                                isGenerating || newGeneratedShip !== ''
                                    ? 'right'
                                    : ''
                            } ${generatorShip2 == '' ? 'empty' : ''}`}
                        >
                            <ShipDisplay
                                ship={allShips[generatorShip2] ?? null}
                                clickHandlers={
                                    isGenerating ? [] : ['remove-generator']
                                }
                                isLarge
                                showPopup={!isGenerating}
                                showOutline={false}
                            />
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
}
