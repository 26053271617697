import { Ship } from "./ship";

class ShipCollection {
    static TYPE = "ShipCollection";

    public ships: Record<number, Ship>;
    public nextShipId: number;

    constructor() {
        this.ships = {}
        this.nextShipId = 0;
    }

    getType() { return ShipCollection.TYPE; }
    static getType() { return ShipCollection.TYPE; }
}

export { ShipCollection };