class ShipMetaData {
    static TYPE = "ShipMetaData";

    public displayName: string;
    public color: string;
    public vertices: Array<Array<number>>;
    public executionFunction: any;

    constructor(displayName: string, color: string, vertices: Array<Array<number>>, executionFunction: any) {
        this.displayName = displayName;
        this.color = color;
        this.vertices = vertices;
        this.executionFunction = executionFunction;
    }

    getType() { return ShipMetaData.TYPE; }
    static getType() { return ShipMetaData.TYPE; }
}

export { ShipMetaData };