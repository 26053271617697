import { Game } from "../model/game.ts";
import { BulletCollection } from "../model/objects/bulletCollection.ts";
import { ShipCollection } from "../model/objects/shipCollection.ts";
import { ShipMetaDataCollection } from "../model/objects/shipMetaDataCollection.ts";
import { shipBulletCollision, transformVertices } from "./utils.ts";

class CollisionSystem {

    private game: Game;

    constructor (game: Game) {
        this.game = game;

        this.update = this.update.bind(this);

        game.gameEventHandlers.addEventToSet('update', this.update);
    }

    update(dt: number) {
        var bulletCollection = this.game.dataManager.read(BulletCollection);
        var shipCollection = this.game.dataManager.read(ShipCollection);
        var shipMetaDataCollection = this.game.dataManager.read(ShipMetaDataCollection);

        for (var sKey in shipCollection.ships) {
            var ship = shipCollection.ships[sKey];
            var shipMetaData = shipMetaDataCollection.metaData[ship.metaDataId];
            var shipRot = 0;
            var shipPos = [ship.pos.x, ship.pos.y];
            var shipGlobalVertices = transformVertices(shipMetaData.vertices, shipRot, shipPos);
            for (var bKey in bulletCollection.bullets) {
                var bullet = bulletCollection.bullets[bKey];
                var bulletPos = [bullet.pos.x, bullet.pos.y];
                var bulletRad = bullet.scale;
                if (bullet.shipId != ship.id)
                {
                    if (shipBulletCollision(shipGlobalVertices, bulletPos, bulletRad)) {
                        this.game.collision(ship, bullet);
                    }
                }
            }
        }

    }
}

export { CollisionSystem };