import { ShipData } from "../../ui/hooks/useApi";

interface ApiResponse<T> {
    data: T | null;
    error: string | null;
  }

interface LemSnippetData {
  description: string;
  code: string;
  game_name: string;
  harness_version: string;
  model_name: string;
  verified: boolean;
  fallback: boolean;
  description_type: string;
  notes: string;
}

interface LemShipData {
  id: number;
  name: string;
  aesthetics: {
    color: string;
  };
  creator_id: string;
  vertices: Array<Array<number>>;
  info: {
    battle_losses: number;
    battle_wins: number;
    tower_wins: number;
    tower_losses: number;
  };
  crowned: boolean;
  is_starting: boolean;
  is_playable: boolean;
  snippet: LemSnippetData;
}
  
class LemCloudApi {
    // TODO: awaiting support.
    // private apiKey: string;
    private baseURL: string;
  
    constructor({ apiKey, baseURL }: { apiKey: string; baseURL: string }) {
    // TODO: awaiting support.
    //   this.apiKey = apiKey;
      this.baseURL = baseURL;
    }
  
    // Method to get the auth headers for requests
    private getAuthHeaders(): HeadersInit {
      return {
        // TODO: awaiting support.
        // Authorization: `Bearer ${this.apiKey}`,
        'content-type': 'application/json',
        // 'accept': 'application/json',
      };
    }
  
    // Fetch all ships from the database
    async getAllShipsMethod(playerId: string): Promise<ApiResponse<any>> {
      try {
        var url = `${this.baseURL}/project-spaceship/ship/all`
        if (playerId) {
          url += `/${playerId}`;
        }
        const response = await fetch(url, {
          method: 'GET',
          headers: this.getAuthHeaders(),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        console.log(data);
        return { data, error: null };
      } catch (error) {
        console.error('Failed to fetch all ships:', error);
        return { data: null, error: error.message };
      }
    }
  
    // Fetch battle sequence ships
    async getBattleSequenceShipsMethod(): Promise<ApiResponse<any>> {
      try {
        const response = await fetch(`${this.baseURL}/project-spaceship/ship/battle`, {
          method: 'GET',
          headers: this.getAuthHeaders(),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to fetch battle sequence ships:', error);
        return { data: null, error: error.message };
      }
    }
  
    // Generate a new ship based on two provided ship IDs
    async generateShipMethod(shipId1: string, shipId2: string, creatorId: string): Promise<ApiResponse<any>> {
      try {
        const jsonData = {
          'id1': shipId1,
          'id2': shipId2,
          'harness_version': '0.1',
          'game_name': 'Project Spaceship',
          'num_neighbors': 3,
          'creator_id': creatorId,
          'method': 'v2.0a1',
        }
        const response = await fetch(`${this.baseURL}/project-spaceship/ship/generate`, {
          method: 'POST',
          headers: this.getAuthHeaders(),
          body: JSON.stringify(jsonData),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to generate ship:', error);
        return { data: null, error: error.message };
      }
    }

    // Push a ship to the DB
    async uploadShipMethod(ship: ShipData): Promise<ApiResponse<ShipData>> {
      try {
        const response = await fetch(`${this.baseURL}/project-spaceship/ship`, {
          method: 'PUT',
          headers: this.getAuthHeaders(),
          body: JSON.stringify(ship),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to PUT ship data:', error);
        return { data: null, error: error.message };
      }
    }

    // Delete a harness
    async deleteHarness(harnessVersion: string): Promise<ApiResponse<any>> {
      try {
        const response = await fetch(`${this.baseURL}/harness/Project Spaceship/${harnessVersion}`, {
          method: 'DELETE',
          headers: this.getAuthHeaders(),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to DELETE harness data:', error);
        return { data: null, error: error.message };
      }
    }

    // Push a new harness
    async uploadHarness(harnessContent: string, harnessVersion: string, deleteOld: boolean = false): Promise<ApiResponse<any>> {
      try {
        if(deleteOld) {
          await this.deleteHarness(harnessVersion)
        }

        const response = await fetch(`${this.baseURL}/harness`, {
          method: 'POST',
          headers: this.getAuthHeaders(),
          body: JSON.stringify({
            'version': harnessVersion,
            'content': harnessContent,
            'game_name': "Project Spaceship",
            "dependencies": "",
          }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to POST harness data:', error);
        return { data: null, error: error.message };
      }
    }

    // Post a battle result
    async postBattleResult(battleResultRequest: object): Promise<ApiResponse<any>> {
      try {
        const response = await fetch(`${this.baseURL}/project-spaceship/ship/battle/result`, {
          method: 'POST',
          headers: this.getAuthHeaders(),
          body: JSON.stringify(battleResultRequest),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to POST battle result:', error);
        return { data: null, error: error.message };
      }
    }

    // Report a ship.
    async postReportShip(reportShipRequest: object): Promise<ApiResponse<any>> {
      try {
        const response = await fetch(`${this.baseURL}/report`, {
          method: 'POST',
          headers: this.getAuthHeaders(),
          body: JSON.stringify(reportShipRequest),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to POST battle result:', error);
        return { data: null, error: error.message };
      }
    }

    // Set isPlayable.
    async patchShipIsPlayable(shipId: string, isPlayable: boolean): Promise<ApiResponse<any>> {
      try {
        const jsonData = JSON.stringify({
            SERVICE_API_KEY: String(process.env.REACT_APP_SERVICE_API_KEY),
            is_playable: isPlayable,
          });
        const url = `${this.baseURL}/project-spaceship/ship/${shipId}`;
        const response = await fetch(url, {
          method: 'PATCH',
          headers: this.getAuthHeaders(),
          body: jsonData,
        });
  
        console.log(response);
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to PATCH ship data:', error);
        return { data: null, error: error.message };
      }
    }

    // Set isPlayable.
    async regenerateSnippet(shipId: string): Promise<ApiResponse<any>> {
      try {
        const response = await fetch(`${this.baseURL}/ship/${shipId}/regenerate_snippet`, {
          method: 'POST',
          headers: this.getAuthHeaders(),
          body: JSON.stringify({
            'SERVICE_API_KEY': process.env.REACT_APP_SERVICE_API_KEY,
          }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
  
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        console.error('Failed to POST ship snippet regeneration:', error);
        return { data: null, error: error.message };
      }
    }
  }

  export { LemCloudApi, LemShipData, LemSnippetData }