import React, { useCallback, useContext, useEffect, useState } from 'react';

export function generateId() {
    return `${Math.random().toString(36).substr(2, 9)}`;
}

export const PlayerInfoContext = React.createContext({
    playerId: '',
    setPlayerId: (id: string) => {},
    inputPlayerId: '',
    setInputPlayerId: (id: string) => {},
});

export const PlayerInfoProvider = ({ children }) => {
    const [internalPlayerId, setInternalPlayerId] = useState<string>('');
    const [internalInputPlayerId, setInputPlayerId] = useState<string>('');

    useEffect(() => {
        const existingPlayerIdCookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith('playerId='))
            ?.split('=')[1];

        if (existingPlayerIdCookie == null) {
            const newPlayerId = generateId();
            setInternalPlayerId(newPlayerId);
            document.cookie = `playerId=${newPlayerId}; Secure`;
        } else {
            setInternalPlayerId(existingPlayerIdCookie);
        }
    }, []);

    const setPlayerId = useCallback((newPlayerId: string) => {
        setInternalPlayerId(newPlayerId);
        document.cookie = `playerId=${newPlayerId}; Secure`;
    }, []);

    return (
        <PlayerInfoContext.Provider
            value={{
                playerId: internalPlayerId,
                setPlayerId,
                inputPlayerId: internalInputPlayerId,
                setInputPlayerId,
            }}
        >
            {children}
        </PlayerInfoContext.Provider>
    );
};

export const usePlayerInfo = () => useContext(PlayerInfoContext);
