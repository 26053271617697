import React from 'react';

import { useShipInfo } from '../hooks/useShipInfo.tsx';
import { usePlayerInfo } from '../hooks/usePlayerInfo.tsx';

import '../styles/ShipInfo.css';
import '../styles/crt.css';

export function ShipInfo() {
    const { shipInfo } = useShipInfo();
    const { playerId } = usePlayerInfo();

    const showCrowned = () => {
        if (shipInfo != null) {
            return shipInfo.crowned && !shipInfo.isStarting;
        }
        return false;
    };

    const isOwned = () => {
        if (shipInfo != null) {
            return shipInfo.creatorId === playerId;
        }
        return false;
    };

    return shipInfo == null ? null : (
        <div className="ShipInfoContainer">
            <div className="ShipDetailItem">
                <span className="ShipDetailItemTitle">{`Ship info for: ${shipInfo?.name}`}</span>
                <span>{shipInfo?.info.battle_wins}</span>
            </div>
            <div className="ShipDetailItem">
                <span className="ShipDetailItemTitle">Battles Won: </span>
                <span>{shipInfo?.info.battle_wins}</span>
            </div>
            <div className={`ShipDetailItem ${showCrowned() ? 'Crowned' : ''}`}>
                <span className="ShipDetailItemTitle">Towers Won: </span>
                <span>{shipInfo?.info.tower_wins}</span>
            </div>
            <div className={`ShipDetailItem ${isOwned() ? 'Owned' : ''}`}>
                <span className="ShipDetailItemTitle">discovered by: </span>
                <span>
                    {isOwned()
                        ? 'you'
                        : `${
                              process.env.REACT_APP_IS_DEBUG === '1'
                                  ? shipInfo?.creatorId
                                  : 'other'
                          }`}
                </span>
            </div>
            <div className="ShipInfo">
                <summary>info</summary>
                <p className="ShipDetails">
                    {shipInfo?.description
                        .trim()
                        .split('\n')
                        .map((line, index) => (
                            // <div>{line.trim()}</div>
                            <div className="ShipDetailItem">
                                {line.split(':').map((lineInner, index) => {
                                    if (index === 0) {
                                        return (
                                            <span className="ShipDetailItemTitle">
                                                {lineInner}:
                                            </span>
                                        );
                                    } else {
                                        return <span>{lineInner}</span>;
                                    }
                                })}
                            </div>
                        ))}
                </p>
            </div>
        </div>
    );
}
