import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Teleports to a random location within a radius of 1 every second. Shoots a ring of bullets that disipates after a couple seconds.
*/
export const shipDescrip4 = `
movement: teleports to a random location within a radius of 1 every second.
weapon: shoots a ring of bullets that disipates after a couple seconds.`;

export const ship4 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'moveTimer', HARNESS.createTimer(e.game));
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        var numBullets = 20;
        for (var i = 0; i < numBullets; ++i) {
            var miniBullet = HARNESS.spawnBullet(e.game, e.ship, 0.1);
            miniBullet.pos = new Vector2(
                e.ship.pos.x,
                e.ship.pos.y,
            );
            const speed = 3.0;
            var angle = (i * (Math.PI * 2.0)) / numBullets;
            const vel = HARNESS.planeVector2FromAngle(angle);
            vel.multiplyScalar(speed);
            HARNESS.setTrait(miniBullet, 'vel', vel);
            HARNESS.setTrait(miniBullet, 'deathTimer', HARNESS.createTimer(e.game));
        }
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Move.
        HARNESS.doIfHasTrait(e.ship, 'moveTimer', (moveTimer) => {
            if (moveTimer.getElapsedTime() > 1) {
                var randOffset = HARNESS.randomDirectionVector2();
                e.ship.pos.add(randOffset);
                moveTimer?.start();
            }
        })

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            if (shootTimer.getElapsedTime() > 2) {
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        })
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        // move bullet.
        HARNESS.doIfHasTrait(e.bullet, 'vel', (vel) => {
            var dpos = new Vector2(vel.x, vel.y).multiplyScalar(
                e.dt
            );
            e.bullet.pos.add(dpos);
        })

        // check to explode bullet.
        HARNESS.doIfHasTrait(e.bullet, 'deathTimer', (deathTimer) => {
            if (deathTimer.getElapsedTime() > 1) {
                HARNESS.deleteBullet(e.game, e.bullet);
            }
        })
    });
};
