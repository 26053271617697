// Imports for ship compilation.
import * as HARNESS from '../harness.ts';
import { Clock, Vector2, MathUtils } from 'three';
const randFloat = MathUtils.randFloat;
const randInt = MathUtils.randInt;

function loadAndExecuteFunction(codeSnippet: string, dependencies, args) {
    const depNames = Object.keys(dependencies);
    const depValues = Object.values(dependencies);

    const fn = new Function(...depNames, `"use strict"; return ${codeSnippet}`);
    const dynamicFunc = fn(...depValues);
    dynamicFunc(args);
}

function loadAndExecuteShipFunction(ship, shipMetaData) {
    return loadAndExecuteFunction(
        shipMetaData.executionFunction,
        {
            Vector2,
            randFloat,
            randInt,
            HARNESS
        },
        ship.eventHandler);
}

export { loadAndExecuteFunction, loadAndExecuteShipFunction };