import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApi } from './useApi.tsx';
import { useBattleSequence } from './useBattleSequence.tsx';
import { useCurrentBattleState } from './useCurrentBattleState.tsx';

const SelectedShipsContext = React.createContext({
    ship1: '',
    ship2: '',
    setShip1: (shipId: string) => {},
    setShip2: (shipId: string) => {},
    shipQueue: [] as Array<string>,
    addShipToQueue: (shipId: string) => {},
    removeShipFromQueue: (shipId: string) => {},
    generatorShip1: '',
    generatorShip2: '',
    addShipToGenerator: (shipId: string) => {},
    removeShipFromGenerator: (shipId: string) => {},
    newGeneratedShip: '',
    setNewGeneratedShip: (shipId: string) => {},
});

export const SelectedShipsProvider = ({ children }) => {
    const { getBattleSequenceShips, battleSequenceShips } = useApi();
    const { setIsSequenceStarted, resetAvailableShips, isSequenceStarted } = useBattleSequence();
    const { onStartBattle } = useCurrentBattleState();

    const [ship1, setShip1] = useState<string>('');
    const [ship2, setShip2] = useState<string>('');
    const [generatorShip1, setGeneratorShip1] = useState<string>('');
    const [generatorShip2, setGeneratorShip2] = useState<string>('');
    const [shipQueue, setShipQueue] = useState<Array<string>>([]);
    const [newGeneratedShip, setNewGeneratedShip] = useState<string>('');

    const addShipToGenerator = useCallback(
        (shipId: string) => {
            if (generatorShip1 === '') {
                setGeneratorShip1(shipId);
            } else if (generatorShip2 === '') {
                setGeneratorShip2(shipId);
            }
        },
        [generatorShip1, generatorShip2]
    );

    const removeShipFromGenerator = useCallback(
        (shipId: string) => {
            if (generatorShip1 === shipId) {
                setGeneratorShip1('');
            } else if (generatorShip2 === shipId) {
                setGeneratorShip2('');
            }
        },
        [generatorShip1, generatorShip2]
    );

    const addShipToQueue = useCallback(
        (shipId: string) => {
            const newShipQueue = [...shipQueue];
            newShipQueue.push(shipId);
            // setShipQueue(newShipQueue);

            // if (ship1 === '') {
            //     setShip1(shipId);
            //     getBattleSequenceShips();
            // }
            setShip1(shipId);
            // getBattleSequenceShips();
        },
        [getBattleSequenceShips, ship1, shipQueue]
    );

    const removeShipFromQueue = useCallback(
        (shipId: string) => {
            const newShipQueue = [...shipQueue];
            const shipIndex = newShipQueue.indexOf(shipId);
            newShipQueue.splice(shipIndex, 1);
            setShipQueue(newShipQueue);

            if (newShipQueue.length > 0) {
                setShip1(newShipQueue[0]);
                getBattleSequenceShips();
            } else {
                setShip1('');
            }
        },
        [getBattleSequenceShips, shipQueue]
    );

    return (
        <SelectedShipsContext.Provider
            value={{
                ship1,
                ship2,
                setShip1,
                setShip2,
                shipQueue,
                addShipToQueue,
                removeShipFromQueue,
                generatorShip1,
                generatorShip2,
                addShipToGenerator,
                removeShipFromGenerator,
                newGeneratedShip,
                setNewGeneratedShip,
            }}
        >
            {children}
        </SelectedShipsContext.Provider>
    );
};

export const useSelectedShips = () => useContext(SelectedShipsContext);
