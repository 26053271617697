import { EventHandler } from "../eventHandler.ts";

class State {

    private systems: Array<any>;

    constructor() {
        this.systems = [];
    }

    attach(system: any) {
        this.systems.push(system);
    }

    start() {
        this.systems.forEach(x => {
            try {
                x.attach();
            } catch(e) { console.warn(e); }
        });
    }

    end() {
        this.systems.forEach(x => {
            try {
                x.detach();
            } catch(e) { console.warn(e); }
        });
    }
}

class StateHandler {

    public eventHandler: EventHandler;

    private states: Record<string, any>;
    private currentState: string;

    constructor() {
        this.states = {};
        this.currentState = "";

        this.eventHandler = new EventHandler();
        this.eventHandler.addEventSet("transition");
    }

    getCurrentState() { return this.currentState; }

    addState(state: State, key: string) {
        this.states[key] = state;
    }

    transition(key: string) {
        if (key == this.currentState) {
            return;
        }
        if (this.currentState != "") {
            this.states[this.currentState].end();
        }
        this.currentState = key;
        this.states[key].start();

        this.eventHandler.callAllEventsInSet("transition", this.currentState);
    }
}

export { State, StateHandler}