import { ShipData } from './useApi';

import React, { useContext, useState } from 'react';

const ShipInfoContext = React.createContext({
    shipInfo: null as ShipData | null,
    setShipInfo: (newShipInfo: ShipData | null) => {},
});

export const ShipInfoProvider = ({ children }) => {
    const [shipInfo, setShipInfo] = useState<ShipData | null>(null);

    return (
        <ShipInfoContext.Provider
            value={{
                shipInfo,
                setShipInfo,
            }}
        >
            {children}
        </ShipInfoContext.Provider>
    );
};

export const useShipInfo = () => useContext(ShipInfoContext);
