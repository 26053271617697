import { RefObject } from 'react';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { BulletView } from './bulletView.ts';
import { ShipView } from './shipView.ts';
import { Game } from '../model/game.ts';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import { EnvironmentView } from './environmentView.ts';
import { TVView } from './tvView.ts';
import { CameraController } from './cameraController.ts';
import { EventHandler } from '../eventHandler.ts';
import { HudView } from './hudView.ts';
import { DeathView } from './deathView.ts';

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import { BloomPass } from 'three/addons/postprocessing/BloomPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

class GameView {

    private renderer: THREE.WebGLRenderer;
    private composer: EffectComposer;

    constructor(ref: RefObject<HTMLDivElement>, refLabel: RefObject<HTMLDivElement>, game: Game) {
        this.dataManager = game.dataManager;

        this.shipViews = [];
        this.bulletViews = [];

        this.scene = new THREE.Scene();
        var parentWidth = ref.current && ref.current.offsetWidth;
        var parentHeight = ref.current && ref.current.offsetHeight;
        var sceneWidth = (parentWidth ?? 0) * 0.03;
        var sceneHeight = (parentHeight ?? 0) * 0.03;
        this.camera = new THREE.OrthographicCamera(
            -sceneWidth / 2,
            sceneWidth / 2,
            sceneHeight / 2,
            -sceneHeight / 2,
            0.01,
            10
        );
        this.camera.position.x = 0;
        this.camera.position.y = 0;
        this.camera.position.z = 0.1;

        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setSize(parentWidth, parentHeight);
        ref.current && ref.current.appendChild(this.renderer.domElement);

        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(parentWidth, parentHeight);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = this.renderer.domElement.offsetTop + 'px';
        refLabel.current && refLabel.current.appendChild(this.labelRenderer.domElement);

        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(new RenderPass(this.scene, this.camera));
        this.composer.addPass(new BloomPass(7, 25, 0.5));
        this.glitchPass = new GlitchPass(0.01);
        this.glitchPass.randX = 0;
        this.glitchTimer = new THREE.Clock();
        this.composer.addPass(this.glitchPass);
        this.composer.addPass(new OutputPass());

        this.gameViewEventHandlers = new EventHandler();
        this.gameViewEventHandlers.addEventSet('update');

        // this.cameraControls = new OrbitControls(
        //     this.camera,
        //     this.renderer.domElement
        // );
        // this.cameraControls.mouseButtons = {
        //     LEFT: THREE.MOUSE.PAN,
        //     ZOOM: THREE.MOUSE.MIDDLE,
        //     PAN: THREE.MOUSE.PAN,
        // };
        // this.cameraControls.touches.ONE = THREE.TOUCH.PAN;
        // this.cameraControls.update();
        this.cameraControls = new CameraController(this, game, this.camera);

        this.environmentView = new EnvironmentView(this.dataManager);
        this.environmentView.addToScene(this.scene);

        this.tvView = new TVView(this.dataManager);
        this.tvView.addToScene(this.scene);

        this.hudView = new HudView(game, this);
        this.hudView.addToScene(this.scene);

        this.deathView = new DeathView(game, this, this.scene);

        this.onCreateNewShip = this.onCreateNewShip.bind(this);
        this.onCreateNewBullet = this.onCreateNewBullet.bind(this);
        this.onRemoveShip = this.onRemoveShip.bind(this);
        this.onRemoveBullet = this.onRemoveBullet.bind(this);
        this.cleanup = this.cleanup.bind(this);

        game.gameEventHandlers.addEventToSet(
            'createNewShip',
            this.onCreateNewShip
        );
        game.gameEventHandlers.addEventToSet('removeShip', this.onRemoveShip);
        game.gameEventHandlers.addEventToSet(
            'createNewBullet',
            this.onCreateNewBullet
        );
        game.gameEventHandlers.addEventToSet(
            'removeBullet',
            this.onRemoveBullet
        );
        game.gameEventHandlers.addEventToSet('cleanup', this.cleanup);
    }

    onCreateNewShip(shipId) {
        var shipView = new ShipView(this.dataManager, shipId);
        shipView.addToScene(this.scene);
        this.shipViews.push(shipView);
    }

    onCreateNewBullet(bulletId) {
        var bulletView = new BulletView(this.dataManager, bulletId);
        bulletView.addToScene(this.scene);
        this.bulletViews.push(bulletView);
    }

    onRemoveShip(shipId) {
        if (shipId != -1) {
            this.shipViews.forEach((shipView) => {
                if (shipView.shipId === shipId) {
                    shipView.removeFromScene(this.scene);
                    const index = this.shipViews.indexOf(shipView);
                    this.shipViews.splice(index, 1);
                }
            });
        }
    }

    onRemoveBullet(bulletId) {
        if (bulletId != -1) {
            this.bulletViews.forEach((bulletView) => {
                if (bulletView.bulletId === bulletId) {
                    bulletView.removeFromScene(this.scene);
                    const index = this.bulletViews.indexOf(bulletView);
                    this.bulletViews.splice(index, 1);
                }
            });
        }
    }

    cleanup() {
        this.hudView.cleanup(this.scene);
    }

    update(dt) {
        this.gameViewEventHandlers.callAllEventsInSet('update', dt);
        TWEEN.update();
    }

    glitch() {
        this.glitchPass.randX = 1;
        this.glitchTimer.start();
    }

    draw() {
        this.environmentView.draw();
        this.shipViews.forEach((shipView) => {
            shipView.draw();
        });
        this.bulletViews.forEach((bulletView) => {
            bulletView.draw();
        });

        this.composer.render();
        this.labelRenderer.render(this.scene, this.camera);

        if (this.glitchTimer.getElapsedTime() > 0.2) {
            this.glitchTimer.stop();
        }

        if (!this.glitchTimer.running) {
            this.glitchPass.randX = 0;
        }
    }
}

export { GameView };
