import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LemCloudApi, LemShipData } from '../../api/lemcloud/lemCloudApi.ts';
import { UploadExamplesHelper } from '../../api/lemcloud/helpers/uploadExamplesHelper.ts';
import { UploadHarnessHelper } from '../../api/lemcloud/helpers/uploadHarnessHelper.ts';

export interface ShipData {
    id: string;
    name: string;
    aesthetics: {
        color: string;
    };
    vertices: Array<Array<number>>;
    info: {
        battle_wins: number;
        battle_losses: number;
        tower_wins: number;
        tower_losses: number;
    };
    crowned: boolean;
    eventHandlers: string;
    description: string;
    isStarting: boolean;
    isPlayable: boolean;
    creatorId: string;
}

interface ShipCollection {
    [key: string]: ShipData;
}

// Default state
const ApiContext = React.createContext({
    getAllShips: (playerId: string) => {},
    allShips: {} as ShipCollection,
    getBattleSequenceShips: (): Promise<boolean> =>
        new Promise((resolve) => true),
    battleSequenceShips: [] as Array<ShipData>,
    generateShip: (shipId1: string, shipId2: string, playerId: string): Promise<object> =>
        new Promise((resolve) => ''),
    uploadAllExamples: () => {},
    uploadHarness: () => {},
    postBattleResult: (battleResultRequest: object, playerId: string) => 
        new Promise((resolve) => ''),
    postReportShip: (shipId: string, playerId: string) => 
        new Promise((resolve) => ''),
    patchShipIsPlayable: (shipId: string, isPlayable: boolean) =>
        new Promise((resolve) => ''),
    regenerateSnippet: (shipId: string, playerId: string) =>
        new Promise((resolve) => ''),
});

var apiUrl = '';
try {
    if (process.env.REACT_APP_IS_LEM_LOCAL === '1') {
        apiUrl = 'http://127.0.0.1:5000/lemcloud';
    } else {
        apiUrl = 'https://heroic-quarter-production.up.railway.app/lemcloud'
    }
} catch (e) {
    console.error(
        'No REACT_APP_IS_LEM_LOCAL env variable found. Falling back to non-local.'
    );
    apiUrl = 'https://heroic-quarter-production.up.railway.app/lemcloud'
}
const lemCloudApi = new LemCloudApi({ apiKey: '', baseURL: apiUrl });

export const ApiProvider = ({ children }) => {
    const [allShips, setAllShips] = useState<ShipCollection>(
        {} as ShipCollection
    );
    const [battleSequenceShips, setBattleSequenceShips] = useState<
        Array<ShipData>
    >([]);

    function postProcessCode(codeSnippet: string, stringsToReplace): string {
        let modifiedCode = codeSnippet;

        // Loop through each string to replace
        stringsToReplace.forEach((strObj) => {
            // Create a regex pattern to find the string globally and case-sensitive
            const pattern = new RegExp(strObj.find, 'g');
            // Replace occurrences in the code
            modifiedCode = modifiedCode.replace(pattern, strObj.replaceWith);
        });

        return modifiedCode;
    }

    const replacements = [
        { find: 'three__WEBPACK_IMPORTED_MODULE_0__.', replaceWith: '' },
        { find: 'three__WEBPACK_IMPORTED_MODULE_1__.', replaceWith: '' },
        {
            find: 'three_src_math_MathUtils__WEBPACK_IMPORTED_MODULE_2__.',
            replaceWith: '',
        },
        {
            find: '_harness_ts__WEBPACK_IMPORTED_MODULE_0__',
            replaceWith: 'HARNESS',
        },
    ];

    function lemShipToGameShip(lemShip: LemShipData): ShipData {
        return {
            id: String(lemShip.id),
            name: lemShip.name,
            aesthetics: lemShip.aesthetics,
            vertices: lemShip.vertices,
            info: lemShip.info,
            crowned: lemShip.crowned,
            isStarting: lemShip.is_starting,
            isPlayable: lemShip.is_playable,
            eventHandlers: lemShip.snippet.code,
            description: lemShip.snippet.description,
            creatorId: lemShip.creator_id,
        };
    }

    function gameShipToLemShip(gameShip: ShipData, creatorId: string): LemShipData {
        return {
            name: gameShip.name,
            aesthetics: gameShip.aesthetics,
            creator_id: creatorId,
            vertices: gameShip.vertices,
            kguid: gameShip.name,
            info: {
                battle_wins: 0,
                battle_losses: 0,
                tower_wins: 0,
                tower_losses: 0,
            },
            crowned: true,
            is_starting: gameShip.isStarting,
            is_playable: true,
            snippet: {
                description: gameShip.description,
                description_embedding: '',
                code: postProcessCode(
                    gameShip.eventHandlers.toString(),
                    replacements
                ),
                game_name: 'Project Spaceship',
                harness_version: '0.1',
                model_name: 'human',
                verified: true,
                fallback: true,
                description_type: '',
                notes: '',
            },
        };
    }

    const getAllShips = useCallback(async (playerId, clear=true) => {
        if (clear) {
            setAllShips({} as ShipCollection);
        }
        // Fetch all ships in database and set them in the hook state
        const response = await lemCloudApi.getAllShipsMethod(playerId);

        if (response.error == null) {
            const dictData = response.data.reduce(
                (accumulator, currentValue) => {
                    var gameShip = lemShipToGameShip(currentValue);
                    accumulator[gameShip.id] = gameShip;
                    return accumulator;
                },
                {}
            );
            setAllShips(dictData);
        } else {
            setAllShips({} as ShipCollection);
        }
    }, []);

    const getBattleSequenceShips = useCallback(async () => {
        // Fetch battle tower ships and set them in the hook state
        const response = await lemCloudApi.getBattleSequenceShipsMethod();

        if (response.error == null) {
            const battleShipsData = response.data.map((x: LemShipData) => {
                return lemShipToGameShip(x);
            });
            setBattleSequenceShips(battleShipsData);
            return true;
        } else {
            setBattleSequenceShips([]);
            return false;
        }
    }, [setBattleSequenceShips]);

    const generateShip = useCallback(
        async (shipId1: string, shipId2: string, playerId: string) => {
            // Pass two ship IDs to generate function
            const response = await lemCloudApi.generateShipMethod(
                shipId1,
                shipId2,
                playerId,
            );

            // If the ship was successfully generated, retrieve all ships from the database again,
            // which should now include the new generated ship
            if (response.error == null) {
                await getAllShips(playerId, false);

                // Return the ID of the generated ship so we know which one has been selected
                // to attempt a battle tower
                return {
                    shipId: String(response.data.id), 
                    info: {
                        type: "INFO",
                        message: "ship successfully crafted.",
                        error: false,
                    }
                };
            } else {
                return {
                    shipId: "", 
                    info: {
                        type: "ERROR",
                        message: response.error,
                        error: true,
                    }
                };
            }
        },
        [getAllShips]
    );

    const postBattleResult = useCallback(
        async (battleResultRequest: object, playerId: string) => {
            const response = await lemCloudApi.postBattleResult(battleResultRequest);
            if (response.error == null) {
                await getAllShips(playerId, false);
            }
        }, [getAllShips])

    const postReportShip = useCallback(
        async (shipId: string, playerId: string) => {
            const response = await lemCloudApi.postReportShip({
                'object_id': Number(shipId),
                'game_name': 'Project Spaceship',
                'user': playerId,
            });
        }, [])

    const patchShipIsPlayable = useCallback(
        async (shipId: string, isPlayable: boolean) => {
            const response = await lemCloudApi.patchShipIsPlayable(shipId, isPlayable);
        }, [])

    const regenerateSnippet = useCallback(
        async (shipId: string, playerId: string) => {
            const response = await lemCloudApi.regenerateSnippet(shipId);
            if (response.error == null) {
                await getAllShips(playerId, true);
            }
        }, [getAllShips])

    const uploadAllExamples = useCallback(async () => {
        var uploadExamplesHelper = new UploadExamplesHelper(lemCloudApi);
        await uploadExamplesHelper.uploadAllShips(gameShipToLemShip);
    }, []);

    const uploadHarness = useCallback(async () => {
        var uploadHarnessHelper = new UploadHarnessHelper(lemCloudApi);
        await uploadHarnessHelper.uploadHarness();
    }, []);

    return (
        <ApiContext.Provider
            value={{
                getAllShips,
                allShips,
                getBattleSequenceShips,
                battleSequenceShips,
                generateShip,
                uploadAllExamples,
                uploadHarness,
                postBattleResult,
                postReportShip,
                patchShipIsPlayable,
                regenerateSnippet,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => useContext(ApiContext);
