import { ShipMetaData } from "./shipMetaData";

class ShipMetaDataCollection {
    static TYPE = "ShipMetaDataCollection";

    public metaData: Record<string, ShipMetaData>;
    public nextMetaDataId: number;

    constructor() {
        this.metaData = {}
        this.nextMetaDataId = 0;
    }

    getType() { return ShipMetaDataCollection.TYPE; }
    static getType() { return ShipMetaDataCollection.TYPE; }
}

export { ShipMetaDataCollection };