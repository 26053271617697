class Timer {
    static TYPE = "Timer";

    public autoStart: boolean;
    public elapsedTime: number;
    public running: boolean;

    constructor(autoStart?: boolean) {
        this.autoStart = autoStart;
        this.running = false;
        this.elapsedTime = 0;
    }

    update(dt: number) {
        if (this.autoStart && !this.running) {
            this.start();
        }

        if (this.running) {
            this.elapsedTime += dt;
        }
    }

    getElapsedTime() {
        return this.elapsedTime;
    }

    start() {
        this.running = true;
        return this.elapsedTime = 0;
    }

    stop() {
        this.running = false;
    }

    getType() { return Timer.TYPE; }
    static getType() { return Timer.TYPE; }
}

export { Timer }