import * as THREE from 'three';
import { FontLoader } from 'three/addons/loaders/FontLoader.js'
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';

export function createFilledMesh(vertices2D) {
    // First, convert vertices to 3D by adding a '0' as the z coordinate
    const vertices3D = [];
    vertices2D.forEach(vertex => {
        vertices3D.push(vertex[0], vertex[1], 0);
    });

    // Create a BufferGeometry instance
    const geometry = new THREE.BufferGeometry();

    // Create a Float32Array to store vertices positions
    const verticesFloat32Array = new Float32Array(vertices3D);

    // Create an attribute for the positions of vertices and add it to the geometry
    geometry.setAttribute('position', new THREE.BufferAttribute(verticesFloat32Array, 3));
    
    // Automatically computing normals
    geometry.computeVertexNormals();

    // Define the material (color, side, etc.)
    const material = new THREE.MeshBasicMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide });

    // Create the mesh with geometry and material
    const mesh = new THREE.Mesh(geometry, material);

    // For simplicity, let's manually create faces (triangles) assuming a convex shape
    // Note: For complex or concave shapes, consider using THREE.Shape or auto-triangulation libraries
    const indices = [];
    for (let i = 1; i < vertices2D.length - 1; i++) {
        indices.push(0, i, i + 1);
    }
    
    // Convert array of indices to a Uint16Array and add it to the geometry as an index
    geometry.setIndex(indices);

    return mesh;
}

export function createOutlineMesh(vertices2D, linewidth, color: string) {
    // Convert 2D vertices to 3D
    const points = vertices2D.map(vertex => new THREE.Vector3(vertex[0], vertex[1], 0));
    // Adding the first vertex again at the end to close the shape
    points.push(points[0]);

    // Create a geometry
    const geometry = new THREE.BufferGeometry().setFromPoints(points);

    // Create a material, specifying the color and linewidth
    const material = new THREE.LineBasicMaterial({ color: new THREE.Color(color), linewidth: linewidth });

    // Create a line loop which is similar to THREE.Line but connects the final and first points
    const line = new THREE.LineLoop(geometry, material);

    return line;
}

export function createExplodedOutlineMesh(vertices2D, linewidth, color: string) {
    var meshes = []
    for (let i = 0; i < vertices2D.length; ++i) {
        var j = (i + 1) % vertices2D.length;
        var line = createOutlineMesh([vertices2D[i], vertices2D[j]], linewidth, color);
        line.normal = new THREE.Vector3(0, 0, 0);
        line.normal.x = (vertices2D[i][0] + vertices2D[j][0]) / 2;
        line.normal.y = (vertices2D[i][1] + vertices2D[j][1]) / 2;
        line.normal.normalize();
        meshes.push(line);
    }
    return meshes;
}

export function createFontMesh(text) {
    let textMesh;
    const loader = new FontLoader();
    loader.load('fonts/helvetiker_regular.typeface.json', function (font) {
        const textGeometry = new TextGeometry( text, {
            font: font,
            size: 10,
            height: 1,
            curveSegments: 12,
            bevelEnabled: false,
            bevelThickness: 0.5,
            bevelSize: 0.3,
            bevelOffset: 0,
            bevelSegments: 5,
        });

        const textMaterial = new THREE.MeshBasicMaterial( { color: 0xffffff } );

        textMesh = new THREE.Mesh( textGeometry, textMaterial );
    },
    function (progress) {},
    function (err) {console.error(err);});

    return textMesh;
}

export function createCssLabel(text) {
    const textObj = document.createElement('span');
    textObj.className = 'label';
    textObj.style.color = '#ffffff';
    textObj.style.fontFamily = 'Courier Prime';
    textObj.style.opacity = '0.5';
    textObj.style.fontSize = '20px';
    textObj.style.textAlign = 'center';
    textObj.textContent = text;

    const label = new CSS2DObject( textObj );
    label.element.className += " crt";
    // label.center.set(-0.5, -0.5);
    return label
}