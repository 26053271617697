import React, { useContext, useState } from 'react';

const GameStateContext = React.createContext({
    isPlaying: false,
    setIsPlaying: (value: boolean) => {},
});

export const GameStateProvider = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    return (
        <GameStateContext.Provider
            value={{
                isPlaying,
                setIsPlaying,
            }}
        >
            {children}
        </GameStateContext.Provider>
    );
}

export const useGameState = () => useContext(GameStateContext);