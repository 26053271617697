class Environment {
    static TYPE = "Environment";

    public boundsLeft: number;
    public boundsTop: number;
    public boundsRight: number;
    public boundsBottom: number;
    public shrinkSpeed: number;

    constructor() {
        this.boundsLeft = -15;
        this.boundsTop = 15;
        this.boundsRight = 15;
        this.boundsBottom = -15;
        this.shrinkSpeed = 1;
    }

    getType() { return Environment.TYPE; }
    static getType() { return Environment.TYPE; }
}

export { Environment };