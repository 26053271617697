import { Ship } from './objects/ship.ts';
import { EventHandler } from '../eventHandler.ts';

import { ShootEvent } from '../events/shootEvent.ts';
import { DataManager } from './dataManager.ts';
import { ShipCollection } from './objects/shipCollection.ts';
import { BulletCollection } from './objects/bulletCollection.ts';
import { ShipMetaDataCollection } from './objects/shipMetaDataCollection.ts';
import { TimersCollection } from  './objects/timersCollection.ts'
import { Bullet } from './objects/bullet.ts';
import { Environment } from './objects/environment.ts';
import { ShipMetaData } from './objects/shipMetaData.ts';
import { Vector2 } from 'three';
import { StateHandler } from '../utils/stateHandler.ts';
import { TVState } from './tvState.ts';
import { StartState } from './startState.ts';
import { PlayState } from './playState.ts';
import { EndState } from './endState.ts';
import { CollisionEvent } from '../events/collisionEvent.ts';
import { DeathState } from './deathState.ts';
import { BattleData } from './objects/battleData.ts';

class Game {

    public dataManager: DataManager;
    public gameEventHandlers: EventHandler;
    public stateHandler: StateHandler;

    private winnerCallbackFunction: any;

    constructor(winnerCallbackFunction: any) {
        this.winnerCallbackFunction = winnerCallbackFunction;

        this.dataManager = new DataManager();
        this.dataManager.write(new ShipMetaDataCollection());
        this.dataManager.write(new ShipCollection());
        this.dataManager.write(new BulletCollection());
        this.dataManager.write(new Environment());
        this.dataManager.write(new BattleData());
        this.dataManager.write(new TimersCollection());

        this.gameEventHandlers = new EventHandler();
        this.gameEventHandlers.addEventSet('update');
        this.gameEventHandlers.addEventSet('collision');
        this.gameEventHandlers.addEventSet('createNewShip');
        this.gameEventHandlers.addEventSet('removeShip');
        this.gameEventHandlers.addEventSet('createNewBullet');
        this.gameEventHandlers.addEventSet('removeBullet');
        this.gameEventHandlers.addEventSet('cleanup');
        this.gameEventHandlers.addEventSet('createNewTimer');

        this.stateHandler = new StateHandler();
        this.stateHandler.addState(new TVState(this), "tv");
        this.stateHandler.addState(new StartState(this), "start");
        this.stateHandler.addState(new PlayState(this), "play");
        this.stateHandler.addState(new DeathState(this), "death");
        this.stateHandler.addState(new EndState(this), "end");

        this.onChangeState = this.onChangeState.bind(this);
        this.stateHandler.eventHandler.addEventToSet('transition', this.onChangeState);
    }

    evaluateWinner() {
        // TODO: temp assume only surviving ship is winner.
        var shipCollection = this.dataManager.read(ShipCollection);
        for (var key in shipCollection.ships) {
            var ship = shipCollection.ships[key];
            this.winnerCallbackFunction(ship.id);
            return;
        }
        // Fallback.
        this.winnerCallbackFunction("0");
    }

    addNewShipMetaData(shipMetaData: ShipMetaData) {
        var metaDataCollection = this.dataManager.read(ShipMetaDataCollection);
        metaDataCollection.metaData[metaDataCollection.nextMetaDataId++] = shipMetaData;
        this.dataManager.write(metaDataCollection);
    }

    addNewShip(pos: Vector2, rot: number, scale: number, metaDataId: number) {
        var shipCollection = this.dataManager.read(ShipCollection);
        var ship = new Ship(pos, rot, scale, shipCollection.nextShipId++, metaDataId);
        shipCollection.ships[ship.id] = ship;
        this.dataManager.write(shipCollection);

        this.gameEventHandlers.callAllEventsInSet('createNewShip', ship.id);
        return ship;
    }

    removeShip(ship: Ship) {
        this.gameEventHandlers.callAllEventsInSet('removeShip', ship.id);
        var shipCollection = this.dataManager.read(ShipCollection);
        delete shipCollection.ships[ship.id];
        this.dataManager.write(shipCollection);
    }

    addBullet(scale: number, shipId: number) {
        var bulletCollection = this.dataManager.read(BulletCollection);
        var bullet = new Bullet(scale, bulletCollection.nextBulletId++, shipId);
        bulletCollection.bullets[bullet.id] = bullet;
        this.dataManager.write(bulletCollection);

        this.gameEventHandlers.callAllEventsInSet('createNewBullet', bullet.id);
        return bullet;
    }

    removeBullet(bullet: Bullet) {
        this.gameEventHandlers.callAllEventsInSet('removeBullet', bullet.id);
        var bulletCollection = this.dataManager.read(BulletCollection);
        delete bulletCollection.bullets[bullet.id];
        this.dataManager.write(bulletCollection);
    }

    update(dt: number) {
        this.gameEventHandlers.callAllEventsInSet('update', dt);
    }

    shoot(shipToShoot: Ship) {
        shipToShoot.eventHandler.callAllEventsInSet(
            'onShoot',
            new ShootEvent(this, shipToShoot)
        );
    }

    createNewTimer() {
        this.gameEventHandlers.callAllEventsInSet("createNewTimer");
        var timerCollection: TimersCollection = this.dataManager.read(TimersCollection);
        var latestTimer = timerCollection.timers[timerCollection.nextId - 1];
        return latestTimer;
    }

    collision(ship: Ship, bullet: Bullet) {
        this.gameEventHandlers.callAllEventsInSet(
            'collision',
            new CollisionEvent(this, ship, bullet)
        );
        this.stateHandler.transition('death');
        this.removeShip(ship);
    }

    onChangeState(state: string) {
        if (state == 'end') {
            this.endBattle();
        }
    }
    
    endBattle() {
        this.evaluateWinner();
        this.cleanup();
    }

    cleanup() {
        var shipCollection = this.dataManager.read(ShipCollection);
        for (var key in shipCollection.ships) {
            var ship = shipCollection.ships[key];
            this.removeShip(ship);
        }
        var bulletCollection = this.dataManager.read(BulletCollection);
        for (var key in bulletCollection.bullets) {
            var bullet = bulletCollection.bullets[key];
            this.removeBullet(bullet);
        }

        this.gameEventHandlers.callAllEventsInSet('cleanup');
    }
}

export { Game };
