import { Vector2 } from 'three';
import * as HARNESS from '../harness.ts';

/*DESCRIPTION: 
Faces the nearest enemy and moves towards them. Shoots with a short range set of bullets that disappear quickly.
*/
export const shipDescrip7 = `
movement: faces the nearest enemy and moves towards them.
weapon: shoots with a short-range set of bullets that disappear quickly.`;

export const ship7 = (eventHandler) => {
    eventHandler.addEventToSet('onInitShip', (e) => {
        HARNESS.setTrait(e.ship, 'shootTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onShoot', (e) => {
        var bullet = HARNESS.spawnBullet(e.game, e.ship, 0.05);
        bullet.pos = new Vector2(
            e.ship.pos.x,
            e.ship.pos.y,
        );
        const speed = 3.0;
        const vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(speed);
        HARNESS.setTrait(bullet, 'vel', vel);
        HARNESS.setTrait(bullet, 'deathTimer', HARNESS.createTimer(e.game));
    });

    eventHandler.addEventToSet('onUpdateShip', (e) => {
        // Find nearest enemy.
        var closest = HARNESS.getClosestShipsTo(e.game, e.ship)[0];
        if (closest != null) {
            var dist = new Vector2();
            dist.subVectors(closest.pos, e.ship.pos);
            dist.normalize();
            e.ship.rot = HARNESS.getRotFromPlaneVector2(dist);
        }

        // Move.
        var speed = 1.0;
        var vel = HARNESS.getForward(e.ship.rot);
        vel.multiplyScalar(speed);
        e.ship.pos.add(vel.multiplyScalar(e.dt));

        // Fire.
        HARNESS.doIfHasTrait(e.ship, 'shootTimer', (shootTimer) => {
            var tElapsed = e.ship.traits['shootTimer']?.getElapsedTime();
            if (shootTimer.getElapsedTime() > 0.05) {
                // fires every 0.05 seconds.
                HARNESS.shoot(e.game, e.ship);
                shootTimer.start();
            }
        });
    });

    eventHandler.addEventToSet('onUpdateBullets', (e) => {
        HARNESS.doIfHasTrait(e.bullet, 'vel', (vel) => {
            var dpos = new Vector2(vel.x, vel.y).multiplyScalar(
                e.dt
            );
            e.bullet.pos.add(dpos);
        });

        HARNESS.doIfHasTrait(e.bullet, 'deathTimer', (deathTimer) => {
            if (deathTimer.getElapsedTime() > 0.6) {
                HARNESS.deleteBullet(e.game, e.bullet);
            }
        });
    });
};
