
import { Game } from "../model/game";
import { Bullet } from "../model/objects/bullet";
import { Ship } from "../model/objects/ship";

class CollisionEvent {

    public game: Game;
    public ship: Ship;
    public bullet: Bullet;

    constructor(game: Game, ship: Ship, bullet: Bullet) {
        this.game = game;
        this.ship = ship;
        this.bullet = bullet;
    }
}

export { CollisionEvent };